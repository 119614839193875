import { useMutation } from '@tanstack/react-query';

import useNotesApi from '@app/services/notes/useNotesApi';

const useEditNote = () => {
  const { editNote } = useNotesApi();
  const mutation = useMutation({
    mutationFn: editNote,
    mutationKey: ['notes-edit'],
  });

  return mutation;
};

export default useEditNote;
