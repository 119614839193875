import { useInfiniteQuery } from '@tanstack/react-query';

import { Group } from '@app/services/groups/types';
import useGroupsApi, {
  groupResponseToGroup,
} from '@app/services/groups/useGroupsApi';

const useInfiniteGroups = (userId?: string) => {
  const { get } = useGroupsApi();

  const query = useInfiniteQuery({
    queryFn: ({ pageParam }) =>
      get({ page: pageParam, rowsPerPage: 5 }, userId),
    queryKey: ['groups-infinite', userId],
    getNextPageParam: (lastPage) =>
      lastPage.pagination.links.next !== null
        ? lastPage.pagination.page + 1
        : null,
    initialPageParam: 1,
    refetchOnWindowFocus: false,
    select: (data) => {
      const groups: Group[] = [];
      data.pages.forEach((page) => {
        groups.push(
          ...page.data.map((groupResponse) =>
            groupResponseToGroup(groupResponse, userId)
          )
        );
      });

      return {
        ...data,
        groups,
      };
    },
  });

  return query;
};

export default useInfiniteGroups;
