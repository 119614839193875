import { Range } from '@filot/types/api';

export interface RangedAuthConfig {
  audience: string;
}

export const configs: Record<Range, RangedAuthConfig> = {
  notes: {
    audience: 'https://notes.api.filot.io/',
  },
  organization: {
    audience: 'https://organizations.api.filot.io/',
  },
  groups: {
    audience: 'https://groups.api.filot.io/',
  },
  files: {
    audience: 'https://files.api.filot.io/',
  },
  users: {
    audience: 'https://users.api.filot.io/',
  },
  tags: {
    audience: 'https://tags.api.filot.io/',
  },
};
