import { getEnv } from '@app/config/env';
import { getRangedAccessToken, getInstance } from '@app/utils/http-instance';
import { ServiceOptions, SuccessfulResponse } from '@filot/types/api';

import getOrganizationMock from './mock';
import {
  DnsRecord,
  Invitation,
  OrganizationApi,
  UserOrganizationsResponse,
  UserResponse,
  UserRolesResponse,
} from './types';

const getOrganizationService = ({
  url,
  getAccessTokenSilently,
}: ServiceOptions): OrganizationApi => {
  const {
    filot: { organizations },
  } = getEnv();

  return {
    ...getOrganizationMock(),
    createOrganization: async ({ name, domain, displayName }) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? organizations.domain}/api/v1/organizations/create`,
        {
          name,
          display_name: displayName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            domain,
          },
        }
      );
    },

    getOrganization: async (userId?: string) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      if (!userId) {
        return null;
      }

      const { data } = await instance.get<
        SuccessfulResponse<UserOrganizationsResponse[]> | undefined
      >(
        `${url ?? organizations.domain}/api/v1/users/${userId}/organizations/get`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!data) {
        return null;
      }

      return data;
    },

    getUsers: async (id, page) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<UserResponse>(
        `${url ?? organizations.domain}/api/v1/organizations/${id}/members/get`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            page,
            page_size: 10,
          },
        }
      );

      return data;
    },

    inviteUser: async ({ email, organization: { id, name } }) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? organizations.domain}/api/v1/organizations/${id}/invitations/create`,
        {
          inviter: {
            name,
          },
          invitee: {
            email,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    removeUserFromOrganization: async ({ user, organization: { id } }) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(
        `${url ?? organizations.domain}/api/v1/organizations/${id}/members/delete`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          data: { members: [user.user_id] },
        }
      );
    },

    getUserRoles: async (userId, organizationId) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<
        SuccessfulResponse<UserRolesResponse>
      >(
        `${url ?? organizations.domain}/api/v1/organizations/${organizationId}/members/${userId}/roles/get`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data.data;
    },

    generateDnsRecord: async (domainToVerify) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<DnsRecord>>(
        `${url ?? organizations.domain}/api/private/generate-dns-records`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            domain: domainToVerify,
          },
        }
      );

      return data.data;
    },

    verifyDns: async (domain) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<SuccessfulResponse<DnsRecord>>(
        `${url ?? organizations.domain}/api/private/verify-dns-records`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            domain,
          },
        }
      );

      return data.data;
    },

    bulkInvite: async ({ emails, organization: { id, name } }) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? organizations.domain}/api/v1/organizations/${id}/invitations/bulk/create`,
        emails.map((email) => ({
          inviter: {
            name,
          },
          invitee: {
            email,
          },
        })),
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    transferOwnership: async ({ newUserId, organizationId }) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? organizations.domain}/api/v1/organizations/${organizationId}/transfer_ownership`,
        {
          user_id: newUserId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          },
        }
      );
    },

    uploadNewPicture: async ({ file, organizationId }) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();
      const data = new FormData();
      data.append('image', file);

      await instance.post(
        `${url ?? organizations.domain}/api/v1/organizations/${organizationId}/image`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    },

    update: async ({ organizationId, description, name }) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? organizations.domain}/api/v1/organizations/${organizationId}/modify`,
        {
          display_name: name,
          metadata: {
            description,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          },
        }
      );
    },

    getInvites: async () => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<
        SuccessfulResponse<Invitation[]> | undefined
      >(`${url ?? organizations.domain}/api/v1/users/invitations/get`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      });

      if (!data) {
        return null;
      }

      return data;
    },

    getOrganizationById: async (id) => {
      const accessToken = await getRangedAccessToken(
        'organization',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<
        SuccessfulResponse<UserOrganizationsResponse>
      >(`${url ?? organizations.domain}/api/v1/organizations/${id}/get`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      });

      return data;
    },
  };
};

export default getOrganizationService;
