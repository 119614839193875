import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';

import useFileDetails from '@app/hooks/useFileDetails';
import FileIcon from '@components/file-icon';

interface BulkEditFilesTableRowProps {
  fileId: string;
  selected: boolean;
  onSelect: (fileId: string) => void;
}

const BulkEditFilesTableRow = ({
  fileId,
  selected,
  onSelect,
}: BulkEditFilesTableRowProps) => {
  const { data: file } = useFileDetails(fileId);

  return (
    <TableRow key={file?.fileName ?? fileId} onClick={() => onSelect(fileId)}>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={selected}
          inputProps={{
            'aria-labelledby': fileId,
          }}
        />
      </TableCell>
      <TableCell>
        <div className="gap-sm flex items-center">
          <FileIcon type={file?.type ?? 'pdf'} width={26} height={26} />
          <Typography>{file?.fileName ?? fileId}</Typography>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default BulkEditFilesTableRow;
