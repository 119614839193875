import { useAuth0 } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';

import useOrganization from '@pages/organizations/hooks/useOrganization';

const OrganizationAcl = ({ children }: PropsWithChildren) => {
  const { user } = useAuth0();
  const { data: organization } = useOrganization(user?.sub);

  if (organization) {
    return children;
  }

  return null;
};

export default OrganizationAcl;
