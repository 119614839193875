import { useMutation } from '@tanstack/react-query';

import useTagsApi from '@app/services/tags/useTagsApi';

const useChangeTagColors = () => {
  const { changeColors } = useTagsApi();

  const mutation = useMutation({
    mutationFn: changeColors,
    mutationKey: ['tags-change-colors'],
  });

  return mutation;
};

export default useChangeTagColors;
