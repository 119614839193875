/* eslint-disable @typescript-eslint/require-await */
import {
  AdvancedSearchFormInputs,
  DeletedFileResponse,
  FilePreviewResponse,
  ProposedVersion,
  SharedFileResponse,
  SortOption,
  WorkingClosedVersion,
} from '@app/services/files/types';
import { getOnlyPath } from '@app/utils/files';
import { SuccessfulResponse } from '@filot/types/api';

import mockDeletedFiles from '../../../../../mock/deleted-files.json';
import mock from '../../../../../mock/files.json';

import {
  ClosedVersionsResponse,
  FileResponse,
  FilesApi,
  GroupFolder,
  UploadFileRequest,
} from './types';

const getFilesMock = (): FilesApi => {
  const mockFiles: FileResponse[] = mock;
  const get = async ({
    filters,
    path,
  }: {
    filters?: Partial<AdvancedSearchFormInputs>;
    sortBy?: SortOption;
    sortDirection?: 'asc' | 'desc';
    path?: string;
  }): Promise<{
    files: SuccessfulResponse<FileResponse[]>;
    groupFolders: GroupFolder[];
  }> => {
    const filterFiles = (file: FileResponse) => {
      // if (filters?.tags) {
      //   return file.tags.some((tag) => filters.tags?.includes(tag.name));
      // }
      const filePath = getOnlyPath(file.path);

      if (filters?.path) {
        return (
          `${filters.path.replace(/"/g, '')}` === filePath ||
          `${filters.path.replace(/"/g, '')}/` === filePath
        );
      }

      return (
        (!path && filePath === '/') ||
        path === filePath ||
        encodeURI(path ?? '') === filePath ||
        `${path}/` === filePath
      );
    };

    return {
      files: { success: true, message: '', data: mock.filter(filterFiles) },
      groupFolders: [{ id: '1', name: 'Family Law Unit' }],
    };
  };

  const getFileDetails = (fileId: string) =>
    new Promise<SuccessfulResponse<FileResponse>>((res, rej) => {
      const foundFile = mock.find((file) => file.id === fileId);
      if (foundFile) {
        res({
          success: true,
          message: 'success',
          data: foundFile,
        });
      } else {
        rej(new Error('MOCK_ERROR'));
      }
    });

  const createFolder = ({
    folderName,
    location,
  }: {
    folderName: string;
    location: string;
  }) =>
    new Promise<void>((res, rej) => {
      if (folderName.includes('error')) {
        rej(new Error('MOCK_ERROR'));
      } else {
        mockFiles.push({
          name: folderName,
          path: location,
          organization_id: 'OrganizationA',
          owner: 'ownerName',
          users_with_access: ['publicKey1', 'publicKey2'],
          groups_with_access: ['GroupA'],
          extension: '',
          type: 'document',
          content_id: `new-folder-${folderName}`,
          id: `new-folder-${folderName}`,
          creation: {
            created_by: 'system',
            created_at: '2022-02-05T01:40:00.000Z',
          },
          modifications: ['2022-02-04T01:40:00.000Z'],
          deletion: null,
          locked: false,
          size: 1234,
        });
        res();
      }
    });

  const renameFile = ({
    fileId,
    newName,
  }: {
    fileId: string;
    newName: string;
  }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (newName.includes('error')) {
          rej(new Error('MOCK_ERROR'));
        } else {
          const fileToEdit = mockFiles.find((file) => file.id === fileId);
          if (fileToEdit) {
            fileToEdit.name = `${newName}${fileToEdit.extension}`;
            res();
          }
        }
      }, 1000);
    });

  const moveFile = ({ fileId, newPath }: { fileId: string; newPath: string }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (newPath.includes('error')) {
          rej(new Error('MOCK_ERROR'));
        } else {
          const fileToEdit = mockFiles.find((file) => file.id === fileId);
          if (fileToEdit) {
            fileToEdit.path = newPath;
            res();
          }
        }
      }, 1000);
    });

  const syncFile = async ({ fileId }: { fileId: string }) =>
    new Promise<string>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res(fileId);
        }
      }, 5000);
    });

  const shareFile = async (_: { users: string[] }) =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const uploadFile = async (file: UploadFileRequest) =>
    new Promise<void>((res, rej) =>
      setTimeout(() => {
        if (file.name.includes('error')) {
          return rej(new Error('MOCK_ERROR'));
        } else {
          return res();
        }
      }, 2000)
    );

  const getProposedVersions = async (_fileId: string) =>
    new Promise<ProposedVersion[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res([
            {
              id: '2319283',
              acceptedBy: ['Michael Jackson', 'Mr Test'],
              name: 'file_v2_proposed',
            },
          ]);
        }
      }, 1000);
    });

  const getWorkingVersions = async (_fileId: string) =>
    new Promise<WorkingClosedVersion[]>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res([
            {
              id: '2319283',
              lastModified: '2024-05-03',
              name: 'file_v2_working',
            },
          ]);
        }
      }, 1000);
    });

  const getClosedVersions = async (_fileId: string, page: number) =>
    new Promise<ClosedVersionsResponse>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          if (page === 0) {
            res({
              closedVersions: [
                {
                  id: '2319283',
                  lastModified: '2024-07-03',
                  name: 'file_v0',
                },
                {
                  id: '324fdas',
                  lastModified: '2024-07-02',
                  name: 'file_v1',
                },
                {
                  id: 'fasdgsda',
                  lastModified: '2024-07-01',
                  name: 'file_v1.1',
                },
                {
                  id: 'gsdags',
                  lastModified: '2024-05-03',
                  name: 'file_v1.2',
                },
                {
                  id: 'asfdsafdgsd',
                  lastModified: '2024-01-03',
                  name: 'file_v1.3',
                },
              ],
              total: 7,
            });
          } else {
            res({
              closedVersions: [
                {
                  id: 'gasdgdsag',
                  lastModified: '2023-12-03',
                  name: 'file_v1.4',
                },
                {
                  id: 'gglllglg',
                  lastModified: '2023-05-03',
                  name: 'file_v1.5',
                },
              ],
              total: 7,
            });
          }
        }
      }, 1000);
    });

  const createVersion = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const moveFileToTrash = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const moveFolderToTrash = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const download = () =>
    new Promise<File>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res(new File([], 'downloaded_file'));
        }
      }, 1000);
    });

  const lockFile = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getSharedFile = () =>
    new Promise<SuccessfulResponse<SharedFileResponse>>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            success: true,
            message: 'Shared file found with success!',
            data: {
              file_id: '671bf9c641991f63dee0f733',
              users_with_access: {
                'google-oauth2|105172225577520527444': ['write', 'read'],
                'auth0|6697f174969140973b068d7a': ['write', 'read'],
              },
              groups_with_access: [],
            },
          });
        }
      }, 1000);
    });

  const revokeSharingPermissions = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const updateSharingPermissions = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getFilePreview = (fileId: string) =>
    new Promise<SuccessfulResponse<FilePreviewResponse>>((res, rej) => {
      const link = mock.find((file) => file.id === fileId);
      setTimeout(() => {
        if (Math.random() < 0.5 || !link) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            data: {
              file_id: fileId,
              link: link.preview,
              users_viewing: [],
            },
            message: 'success',
            success: true,
          });
        }
      }, 1000);
    });

  const deleteFilePreview = () =>
    new Promise<void>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res();
        }
      }, 1000);
    });

  const getDeletedFiles = () =>
    new Promise<SuccessfulResponse<DeletedFileResponse[]>>((res, rej) => {
      setTimeout(() => {
        if (Math.random() < 0.5) {
          rej(new Error('MOCK_ERROR'));
        } else {
          res({
            data: mockDeletedFiles,
            message: 'success',
            success: true,
          });
        }
      }, 1000);
    });

  const getFolderDetails = (folderId: string) =>
    new Promise<SuccessfulResponse<FileResponse>>((res, rej) => {
      const foundFile = mock.find((file) => file.id === folderId);
      if (foundFile) {
        res({
          success: true,
          message: 'success',
          data: foundFile,
        });
      } else {
        rej(new Error('MOCK_ERROR'));
      }
    });

  return {
    get,
    getFileDetails,
    createFolder,
    renameFile,
    moveFile,
    syncFile,
    shareFile,
    uploadFile,
    getProposedVersions,
    getClosedVersions,
    getWorkingVersions,
    createVersion,
    moveFileToTrash,
    moveFolderToTrash,
    download,
    lockFile,
    getSharedFile,
    revokeSharingPermissions,
    updateSharingPermissions,
    getFilePreview,
    deleteFilePreview,
    getDeletedFiles,
    getFolderDetails,
  };
};

export default getFilesMock;
