import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useShareFiles = () => {
  const { shareFile } = useFilesApi();
  const query = useMutation({
    mutationFn: shareFile,
    mutationKey: ['file-share'],
  });

  return query;
};

export default useShareFiles;
