import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Organization } from '@app/services/organizations/types';
import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';
import StopClickPropagation from '@components/stop-click-propagation';

interface AcceptInviteModalProps {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
}

const AcceptInviteModal = ({
  isOpen,
  onClose,
  organization,
}: AcceptInviteModalProps) => {
  const { t } = useTranslation();

  const handleAccept = () => {};

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <StopClickPropagation>
        <Box className="w-medium-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
          <PressableIcon
            onClick={onClose}
            icon={<Close title="close" />}
            className="!absolute self-end !p-0"
          />
          <Typography className="!mb-sm !text-2xl">
            {t('organization.invitation.modal.title')}
          </Typography>
          <p>
            {t('organization.invitation.modal.text', {
              organizationName: organization.name,
            })}
          </p>
          <div className="mt-md flex justify-end">
            <Button variant="contained" onClick={handleAccept}>
              {t('organization.invitation.modal.continue')}
            </Button>
          </div>
        </Box>
      </StopClickPropagation>
    </Modal>
  );
};

export default AcceptInviteModal;
