import { useQueryClient } from '@tanstack/react-query';
import { ChangeEventHandler } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import useErrorHandler from '@app/hooks/useErrorHandler';
import UpdateAvatar from '@components/update-avatar';
import useAvatar from '@pages/files/hooks/useAvatar';

import { useOrganizationContext } from '../hooks/useOrganizationContext';
import useUpdateOrganization from '../hooks/useUpdateOrganization';
import useUpdatePicture from '../hooks/useUpdatePicture';

import OrganizationDangerZone from './organization-danger-zone';
import OrganizationOverviewForm, {
  OrganizationOverviewFields,
} from './organization-overview-form';

const OrganizationOverview = () => {
  const { t } = useTranslation();
  const organization = useOrganizationContext();
  const { stringAvatar } = useAvatar();
  const queryClient = useQueryClient();
  const { display, Alert } = useAlert();
  const { isError, isPending, mutateAsync: updatePicture } = useUpdatePicture();
  const {
    isError: isErrorUpdatingOrganization,
    isPending: isPendingUpdateOrganization,
    mutateAsync: updateOrganization,
  } = useUpdateOrganization();
  useErrorHandler(isError, {
    callback: () => {
      display();
    },
  });
  useErrorHandler(isErrorUpdatingOrganization, {
    callback: () => {
      display();
    },
  });

  const handleUpdatePicture: ChangeEventHandler<HTMLInputElement> = (ev) => {
    if (ev.target.files) {
      [...ev.target.files].forEach((file) => {
        void (async () => {
          await updatePicture({
            organizationId: organization.id,
            file,
          });
          await queryClient.invalidateQueries({
            queryKey: ['organizations'],
          });
        })();
      });
    }
  };

  const handleUpdate = async (
    form: OrganizationOverviewFields,
    reset: UseFormReset<OrganizationOverviewFields>
  ) => {
    await updateOrganization({ ...form, organizationId: organization.id });
    reset(form);
  };

  return (
    <div className="mt-lg">
      <h3 className="text-2xl">
        {t('organization.overview.basicInformation.title')}
      </h3>
      <div className="gap-x-md mt-md flex items-center">
        <UpdateAvatar
          className="!h-32 !w-32 !text-5xl"
          {...(organization.picture ? {} : stringAvatar(organization.name))}
          src={organization.picture}
          name="organization-icon"
          onUpdate={handleUpdatePicture}
          isLoading={isPending}
        />
        <OrganizationOverviewForm
          onSubmit={handleUpdate}
          isLoading={isPendingUpdateOrganization}
        />
      </div>
      <OrganizationDangerZone />
      <Alert severity="error">
        {t('organization.overview.basicInformation.error')}
      </Alert>
    </div>
  );
};

export default OrganizationOverview;
