import { useQuery } from '@tanstack/react-query';

import useOrganizationApi from '@app/services/organizations/useOrganizationApi';

const useInvitation = () => {
  const { getInvites } = useOrganizationApi();

  const query = useQuery({
    queryFn: () => getInvites(),
    queryKey: ['organization-invitations'],
    refetchOnMount: true,
    select: (data) => {
      if (data && data.data.length > 0) {
        return data.data[0];
      }

      return undefined;
    },
  });

  return query;
};

export default useInvitation;
