import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useRemoveFile = () => {
  const { removeFile } = useGroupsApi();

  const mutation = useMutation({
    mutationFn: removeFile,
    mutationKey: ['groups-remove-file'],
  });

  return mutation;
};

export default useRemoveFile;
