import { useQuery } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

interface UseProposedVersionsOptions {
  fileId: string;
}

const useProposedVersions = ({ fileId }: UseProposedVersionsOptions) => {
  const { getProposedVersions } = useFilesApi();
  const query = useQuery({
    queryFn: () => getProposedVersions(fileId),
    queryKey: ['files-versions-proposedVersions', fileId],
  });

  return query;
};

export default useProposedVersions;
