import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { isRunningOnElectron } from '@app/utils/environment';

const useAuth = () => {
  const {
    logout: auth0Logout,
    loginWithRedirect,
    handleRedirectCallback,
  } = useAuth0();
  const navigate = useNavigate();

  /* v8 ignore start */
  const login = async () => {
    await loginWithRedirect({
      openUrl: async (url) => {
        if (isRunningOnElectron()) {
          const redirectUrl = await window.auth.login(url);
          await handleRedirectCallback(redirectUrl);
        } else {
          window.location.replace(url);
        }
      },
      authorizationParams: {
        redirect_uri: isRunningOnElectron()
          ? 'https://electron-app.filot.io/callback'
          : location.origin,
      },
    });
  };

  const logout = async () => {
    await auth0Logout({
      logoutParams: {
        returnTo: isRunningOnElectron()
          ? 'https://electron-app.filot.io/logout-callback'
          : location.origin,
        federated: isRunningOnElectron(),
      },
      openUrl: async (url) => {
        if (isRunningOnElectron()) {
          await window.auth.logout(url);
          navigate('/');
        } else {
          window.location.replace(url);
        }
      },
    });
  };
  /* v8 ignore stop */

  return {
    login,
    logout,
  };
};

export default useAuth;
