import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useSharedFile = (sharedFileId: string) => {
  const { getSharedFile } = useFilesApi();

  const query = useQuery({
    queryFn: () => getSharedFile(sharedFileId),
    queryKey: ['files-shared', sharedFileId],
    placeholderData: keepPreviousData,
  });

  return query;
};

export default useSharedFile;
