import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import useAvatar from '@pages/files/hooks/useAvatar';
import useOrganization from '@pages/organizations/hooks/useOrganization';

const ProfileOrganization = () => {
  const { t } = useTranslation();
  const { stringAvatar } = useAvatar();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { data, isFetching, isError, refetch } = useOrganization(user?.sub);

  const handleOrganizationNavigate = () => {
    navigate('/organizations');
  };

  const Content = () => {
    if (isFetching) {
      return (
        <div className="mt-sm flex justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="gap-y-md mt-sm self-center text-center">
          <Typography className="!text-lg">
            {t('profile.organization.error.title')}
          </Typography>
          <p
            className="mb-md cursor-pointer text-lg underline"
            onClick={() => refetch()}
          >
            {t('profile.organization.error.tryAgain')}
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    if (data === null) {
      return (
        <div className="mt-sm flex justify-center">
          <p className="mb-md text-lg">
            {t('profile.organization.noOrganization')}
          </p>
        </div>
      );
    }

    if (data) {
      return (
        <div className="mt-sm gap-x-md flex items-center">
          <Avatar
            className="!h-24 !w-24"
            src={data.branding?.logo_url}
            {...stringAvatar(
              data.display_name,
              undefined,
              data.branding?.logo_url
            )}
          />
          <div className="flex flex-col gap-y-2">
            <p className="text-2xl font-medium">{data.display_name}</p>
            <Button variant="outlined" onClick={handleOrganizationNavigate}>
              {t('profile.organization.access')}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="mt-lg">
      <Typography variant="h2" className="!text-3xl !font-medium">
        {t('profile.organization.title')}
      </Typography>
      <Content />
    </div>
  );
};

export default ProfileOrganization;
