import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ShareFile, ShareUserOrGroup } from '@app/services/files/types';
import GroupIcon from '@assets/files/group.svg?react';
import Close from '@assets/shared/close.svg?react';
import PressableIcon from '@components/pressable-icon';
import StopClickPropagation from '@components/stop-click-propagation';

import ShareFileForm from './share-file-form';

interface ShareFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: (form: ShareFile) => void;
  initialUsers?: ShareUserOrGroup[] | undefined;
}

const ShareFileModal = ({
  isOpen,
  onClose,
  onContinue,
  initialUsers,
}: ShareFileModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal className="flex" open={isOpen} onClose={onClose}>
      <StopClickPropagation>
        <Box
          className="w-tags-editor p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md"
          id="share-file"
        >
          <PressableIcon
            onClick={onClose}
            icon={<Close title="close" />}
            className="!absolute self-end !p-0"
          />
          <div className="flex flex-1 items-center justify-between">
            <Typography className="!text-2xl !font-medium">
              {t('fileList.fileDetails.inviteUsersModal.title')}
            </Typography>
            <Button
              variant="outlined"
              className="!-mt-sm !mr-lg"
              startIcon={<GroupIcon width={18} height={18} />}
              onClick={() => navigate('/groups')}
            >
              {t('fileList.fileDetails.inviteUsersModal.createGroup')}
            </Button>
          </div>
          <ShareFileForm onSubmit={onContinue} initialUsers={initialUsers} />
        </Box>
      </StopClickPropagation>
    </Modal>
  );
};

export default ShareFileModal;
