import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useBulkEdit = () => {
  const { bulkEdit } = useGroupsApi();

  const mutation = useMutation({
    mutationFn: bulkEdit,
    mutationKey: ['bulkEdit'],
  });

  return mutation;
};

export default useBulkEdit;
