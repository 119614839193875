import { useMutation, useQueryClient } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';
import { SuccessfulResponse } from '@filot/types/api';

const useLockFile = () => {
  const { lockFile } = useFilesApi();
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationFn: lockFile,
    mutationKey: ['file-lock'],
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ['fileDetails', variables.fileId],
        (oldData: SuccessfulResponse<File>) =>
          oldData
            ? { data: { ...oldData.data, locked: variables.lock } }
            : undefined
      );
    },
  });

  return query;
};

export default useLockFile;
