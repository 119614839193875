import { useMutation } from '@tanstack/react-query';

import useNotesApi from '@app/services/notes/useNotesApi';

const useDeleteNote = () => {
  const { deleteNote } = useNotesApi();
  const mutation = useMutation({
    mutationFn: deleteNote,
    mutationKey: ['delete-note'],
  });

  return mutation;
};

export default useDeleteNote;
