import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useUploadFile = () => {
  const { uploadFile } = useFilesApi();

  const mutation = useMutation({
    mutationFn: uploadFile,
    mutationKey: ['file-upload'],
  });

  return mutation;
};

export default useUploadFile;
