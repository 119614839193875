import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useMoveFileToTrash = () => {
  const { moveFileToTrash } = useFilesApi();

  const query = useMutation({
    mutationFn: moveFileToTrash,
    mutationKey: ['file-move-to-trash'],
  });

  return query;
};

export default useMoveFileToTrash;
