import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SharedFileResponse } from '@app/services/files/types';
import useFilesApi from '@app/services/files/useFilesApi';
import { SuccessfulResponse } from '@filot/types/api';

const useRevokeSharingPermissions = () => {
  const { revokeSharingPermissions } = useFilesApi();
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationFn: revokeSharingPermissions,
    mutationKey: ['file-permissions-revoke'],
    onSuccess: (_data, variables) => {
      queryClient.setQueryData(
        ['files-shared', variables.fileId],
        (oldData: SuccessfulResponse<SharedFileResponse> | undefined) => {
          if (!oldData) {
            return undefined;
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [variables.userId]: _, ...usersWithAccess } =
            oldData.data.users_with_access;

          return {
            data: { ...oldData.data, users_with_access: usersWithAccess },
          };
        }
      );
    },
  });

  return query;
};

export default useRevokeSharingPermissions;
