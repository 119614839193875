import { getEnv } from '@app/config/env';
import { getInstance, getRangedAccessToken } from '@app/utils/http-instance';
import { ServiceOptions } from '@filot/types/api';

import { NotesApi, NotesResponse } from './types';

const getNotesService = ({
  url,
  getAccessTokenSilently,
}: ServiceOptions): NotesApi => {
  const {
    filot: { notes },
  } = getEnv();

  return {
    getNotes: async (paginationOptions, fileId) => {
      const accessToken = await getRangedAccessToken(
        'notes',
        getAccessTokenSilently
      );
      const instance = getInstance();

      const { data } = await instance.get<NotesResponse>(
        `${url ?? notes.domain}/api/v1/notes/get`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            file_id: fileId,
            page: paginationOptions.page,
            page_size: paginationOptions.rowsPerPage,
          },
        }
      );

      return data;
    },

    createNote: async ({ content, fileId }) => {
      const accessToken = await getRangedAccessToken(
        'notes',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.post(
        `${url ?? notes.domain}/api/v1/notes/create`,
        {
          content,
          file_id: fileId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    },

    editNote: async ({ content, id }) => {
      const accessToken = await getRangedAccessToken(
        'notes',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.put(
        `${url ?? notes.domain}/api/v1/notes/modify`,
        {
          content,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id,
          },
        }
      );
    },

    deleteNote: async (id) => {
      const accessToken = await getRangedAccessToken(
        'notes',
        getAccessTokenSilently
      );
      const instance = getInstance();

      await instance.delete(`${url ?? notes.domain}/api/v1/notes/delete`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          id,
        },
      });
    },
  };
};

export { getNotesService };
