import { ButtonBase, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import useAlert from '@app/hooks/useAlert';
import CloudSyncIcon from '@assets/files/cloud-sync.svg?react';
import DownloadIcon from '@assets/files/download.svg?react';
import HistoryIcon from '@assets/files/history.svg?react';
import LockFileIcon from '@assets/files/lock.svg?react';
import UnlockFileIcon from '@assets/files/unlock.svg?react';
import PreviewIcon from '@assets/files/viewer.svg?react';

import useLockFile from '../hooks/useLockFile';

import { FileDetailsContext } from './file-details';

interface FileDetailsButtonsProps {
  onDownloadClick: () => void;
  onHistoryClick: () => void;
  onPreviewClick: () => void;
  onCloudSyncClick: () => void;
  isLocked: boolean;
}

const ICON_SIZE = 16;

const FileDetailsButtons = ({
  onCloudSyncClick,
  onDownloadClick,
  onPreviewClick,
  onHistoryClick,
  isLocked,
}: FileDetailsButtonsProps) => {
  const { t } = useTranslation();
  const file = useContext(FileDetailsContext);

  const { displayGlobal } = useAlert();
  const { isPending, mutateAsync: lockFile } = useLockFile();

  const handleLockFile = async () => {
    if (isPending) {
      return;
    }
    try {
      if (file) {
        await lockFile({ fileId: file.id, lock: !isLocked });
      }
    } catch (e) {
      displayGlobal({
        text: t('fileList.fileDetails.buttons.lock.error', {
          context: isLocked ? 'unlocking' : 'locking',
        }),
        type: 'error',
      });
    }
  };

  return (
    <div className="mt-md flex flex-col" data-tour="preview-document-step-3">
      <ButtonBase
        focusRipple
        onClick={onPreviewClick}
        className="!p-sm !justify-start"
        data-tour="preview-document-step-2"
      >
        <PreviewIcon
          className="mr-sm text-black"
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
        <Typography>{t('fileList.fileDetails.buttons.preview')}</Typography>
      </ButtonBase>
      <ButtonBase
        focusRipple
        onClick={onDownloadClick}
        className="!p-sm !justify-start"
        data-tour="preview-document-step-4"
      >
        <DownloadIcon className="mr-sm" width={ICON_SIZE} height={ICON_SIZE} />
        <Typography>{t('fileList.fileDetails.buttons.download')}</Typography>
      </ButtonBase>
      <ButtonBase
        focusRipple
        onClick={onHistoryClick}
        className="!p-sm !justify-start"
      >
        <HistoryIcon className="mr-sm" width={ICON_SIZE} height={ICON_SIZE} />
        <Typography>{t('fileList.fileDetails.buttons.history')}</Typography>
      </ButtonBase>
      <ButtonBase
        focusRipple
        onClick={onCloudSyncClick}
        className="!p-sm !justify-start"
      >
        <CloudSyncIcon className="mr-sm" width={ICON_SIZE} height={ICON_SIZE} />
        <Typography>{t('fileList.fileDetails.buttons.cloudSync')}</Typography>
      </ButtonBase>
      <ButtonBase
        focusRipple
        onClick={handleLockFile}
        className="!p-sm !justify-start"
      >
        {isLocked ? (
          <UnlockFileIcon
            className="mr-sm"
            width={ICON_SIZE}
            height={ICON_SIZE}
            title="unlock"
          />
        ) : (
          <LockFileIcon
            className="mr-sm"
            width={ICON_SIZE}
            height={ICON_SIZE}
            title="lock"
          />
        )}
        <Typography>
          {isLocked
            ? t('fileList.fileDetails.buttons.lock.unlockFile')
            : t('fileList.fileDetails.buttons.lock.lockFile')}
        </Typography>
      </ButtonBase>
    </div>
  );
};

export default FileDetailsButtons;
