import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';

import { UserResponse } from '@app/services/users/types';
import useUsersApi from '@app/services/users/useUsersApi';
import { SuccessfulResponse } from '@filot/types/api';

const useUserByEmail = (
  email: string,
  options?: Omit<
    UndefinedInitialDataOptions<
      SuccessfulResponse<UserResponse>,
      Error,
      UserResponse,
      string[]
    >,
    'queryKey'
  >
) => {
  const { getUserByEmail } = useUsersApi();

  const query = useQuery({
    queryFn: () => getUserByEmail(email),
    queryKey: ['users-by-email', email],
    enabled: false,
    retry: (failureCount, error) =>
      failureCount < 3 && error.message !== 'user not found',
    select: ({ data }) => data,
    ...options,
  });

  return query;
};

export default useUserByEmail;
