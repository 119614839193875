import { Button, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useErrorHandler from '@app/hooks/useErrorHandler';
import EmptyIcon from '@assets/organizations/empty.svg?react';

import useCreateOrganization from '../hooks/useCreateOrganization';

import { NewOrganizationFormInputs } from './new-organization-form';
import NewOrganizationModal from './new-organization-modal';

const Empty = () => {
  const { t } = useTranslation();
  const [isNewOrganizationModalOpen, setIsNewOrganizationModalOpen] =
    useState(false);
  const { mutateAsync, isError, reset, isPending } = useCreateOrganization();
  useErrorHandler(isError);
  const queryClient = useQueryClient();

  const handleCreate = async (data: NewOrganizationFormInputs) => {
    await mutateAsync({
      ...data,
      name: data.name.replace(/ /g, '_').toLocaleLowerCase(),
      displayName: data.name,
    });
    setIsNewOrganizationModalOpen(false);
    await queryClient.invalidateQueries({ queryKey: ['organizations'] });
  };

  const handleClose = async () => {
    setIsNewOrganizationModalOpen(false);
    await queryClient.resetQueries({
      queryKey: ['organization-verify-dns-record'],
    });
    await queryClient.resetQueries({
      queryKey: ['organization-generate-dns-record'],
    });
  };

  return (
    <div className="flex flex-1 items-center">
      <div className="flex flex-col">
        <Typography className="!mb-md !flex !gap-x-2 !text-4xl">
          <Trans i18nKey="organization.empty.title">
            With <span className="!text-4xl !font-bold">organizations</span>
            you can
          </Trans>
        </Typography>
        <Trans i18nKey="organization.empty.list">
          <ul className="ml-md w-2/3 list-disc text-lg">
            <li>Make files available for everybody in your organization</li>
            <li>Manage user's access level</li>
            <li>And much more!</li>
          </ul>
        </Trans>
        <Button
          className="!mt-md !px-lg !self-center"
          variant="contained"
          size="large"
          onClick={() => setIsNewOrganizationModalOpen(true)}
        >
          {t('organization.empty.createNew')}
        </Button>
      </div>
      <EmptyIcon height={300} />
      <NewOrganizationModal
        isOpen={isNewOrganizationModalOpen}
        onClose={handleClose}
        onCreate={handleCreate}
        isCreating={isPending}
        isCreateError={isError}
        onTryAgain={reset}
      />
    </div>
  );
};

export default Empty;
