import { useQuery } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useUserHasAccount = (email: string, enabled = true) => {
  const { userHasAccount } = useGroupsApi();
  const query = useQuery({
    queryFn: () => userHasAccount(email),
    queryKey: ['userHasAccount', email],
    enabled,
  });

  return query;
};

export default useUserHasAccount;
