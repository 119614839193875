import {
  Organization,
  User,
  UserOrganizationsResponse,
} from '@app/services/organizations/types';

export const userMock: User = {
  email: 'test@mock.com',
  lastLoginDate: '2024-03-21',
  name: 'John Doe',
  picture: null,
  user_id: 'user id',
  roles: [],
};

export const organizationMock: Organization = {
  name: 'Filot LLC',
  id: 'organization id',
};

export const organizationResponseMock: UserOrganizationsResponse = {
  display_name: 'Test Organization',
  id: 'org_id',
  name: 'Test Organization',
  branding: {
    logo_url: 'https://logo.url',
  },
  enabled_connections: [],
};
