import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useGroupsApi, {
  groupResponseToGroup,
} from '@app/services/groups/useGroupsApi';
import { PaginationOptions } from '@filot/types/api';

const useYourGroups = (
  paginationOptions: PaginationOptions,
  userId?: string
) => {
  const { get } = useGroupsApi();

  const query = useQuery({
    queryFn: () => get(paginationOptions, userId),
    queryKey: ['groups', paginationOptions, userId],
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5,
    select: (data) => ({
      ...data,
      data: data.data.map((groupResponse) =>
        groupResponseToGroup(groupResponse, userId)
      ),
    }),
  });

  return query;
};

export default useYourGroups;
