import { useMutation } from '@tanstack/react-query';

import useOrganizationApi from '@app/services/organizations/useOrganizationApi';

const useUpdateOrganization = () => {
  const { update } = useOrganizationApi();
  const mutation = useMutation({
    mutationFn: update,
    mutationKey: ['organization-update'],
  });

  return mutation;
};

export default useUpdateOrganization;
