import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useNotesApi from '@app/services/notes/useNotesApi';
import { PaginationOptions } from '@filot/types/api';

const useNotes = (paginationOptions: PaginationOptions, fileId: string) => {
  const { getNotes } = useNotesApi();

  const query = useQuery({
    queryFn: () => getNotes(paginationOptions, fileId),
    queryKey: ['notes', fileId, paginationOptions],
    placeholderData: keepPreviousData,
  });

  return query;
};

export default useNotes;
