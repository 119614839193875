import { Button, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import useAuth from '@app/hooks/useAuth';
import Background from '@assets/prelogin/background.svg?react';
import FilotLogo from '@assets/shared/filot-logo.webp';

const Prelogin = () => {
  const { login } = useAuth();
  const { t } = useTranslation();

  return (
    <div className="flex h-full justify-between">
      <div className="my-[min(5%,100px)] flex flex-1 flex-col items-center justify-around">
        <img src={FilotLogo} className="w-80" />
        <Typography
          variant="h1"
          className="!w-[min(60%,500px)] !text-center !text-5xl !font-medium !leading-relaxed"
        >
          <Trans i18nKey="prelogin.text">
            The <b>PILOT</b> for your <b>FILES</b>
          </Trans>
        </Typography>
        <Button
          variant="contained"
          size="large"
          className="!py-sm !px-16"
          onClick={login}
        >
          {t('prelogin.getStarted')}
        </Button>
      </div>
      <div className="flex flex-1 justify-end">
        <Background
          className="!h-full !max-w-full"
          width={undefined}
          height={undefined}
          preserveAspectRatio="xMidYMid slice"
        />
      </div>
    </div>
  );
};

export default Prelogin;
