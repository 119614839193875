import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useUpdateGroupPicture = () => {
  const { updateGroupPicture } = useGroupsApi();
  const mutation = useMutation({
    mutationFn: updateGroupPicture,
    mutationKey: ['groups-update-picture'],
  });

  return mutation;
};

export default useUpdateGroupPicture;
