import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useUpdateGroupDetails = () => {
  const { updateGroupDetails } = useGroupsApi();
  const mutation = useMutation({
    mutationFn: updateGroupDetails,
    mutationKey: ['groups-update-details'],
  });

  return mutation;
};

export default useUpdateGroupDetails;
