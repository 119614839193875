import { useQuery } from '@tanstack/react-query';

import useOrganizationApi from '@app/services/organizations/useOrganizationApi';

const useVerifyDns = (domain: string) => {
  const { verifyDns } = useOrganizationApi();

  const query = useQuery({
    queryFn: () => verifyDns(domain),
    queryKey: ['organization-verify-dns-record', domain],
    enabled: false,
  });

  return query;
};

export default useVerifyDns;
