import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useMemo, useReducer, useRef } from 'react';

import useAppJoyride from '@app/hooks/useAppJoyride';
import { GroupFolder as GroupFolderType } from '@app/services/files/types';
import { File } from '@app/services/files/types';
import FileCard from '@components/file-card';
import OrganizationAcl from '@components/organization-acl';

import { MoveableElements } from '../hooks/useMoveableToFolder';

import FileListContextMenu from './file-list-context-menu';
import FolderCard from './folder-card';
import GroupFolder from './group-folder';

interface FileListProps<T> {
  files?: T[];
  onFileMove?: (elements: MoveableElements) => void;
  onFolderHover?: (elements: MoveableElements) => void;
  onFileClick?: (file: T) => void;
  onFileDoubleClick?: (file: T) => void;
  onFolderClick?: (folder: T) => void;
  selectedFilesId?: string[];
  groupFolders?: GroupFolderType[];
  onGroupFolderClick?: (folder: GroupFolderType) => void;
}

const FileList = <T extends File>({
  files,
  onFileMove,
  onFolderHover,
  onFileClick,
  onFolderClick,
  onFileDoubleClick,
  selectedFilesId = [],
  groupFolders,
  onGroupFolderClick,
}: FileListProps<T>) => {
  const contextAnchor = useRef<HTMLDivElement | null>(null);
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const {
    setStageConfig,
    advanceStep,
    pause,
    resume,
    currentStage,
    step,
    isOnboarding,
    isSingleRun,
  } = useAppJoyride();
  /* v8 ignore start */
  const shouldOpenFileDetail = useMemo(
    () =>
      (currentStage === 5 || currentStage === 6) && isSingleRun && step === 0,
    [currentStage, isSingleRun, step]
  );
  setStageConfig(1, [
    {
      onNext: () => {
        const folder = files?.filter(({ type }) => type === 'folder').at(0);
        if (folder) {
          onFolderClick?.(folder);
        }
      },
    },
  ]);
  setStageConfig(4, [
    {
      onNext: () => {
        pause();
        const file = files?.filter(({ type }) => type !== 'folder').at(0);
        if (file) {
          onFileClick?.(file);
        }
      },
    },
  ]);
  setStageConfig(7, [
    {
      onNext: () => {
        const groupFolder = groupFolders?.at(0);
        if (groupFolder) {
          onGroupFolderClick?.(groupFolder);
        }
      },
    },
  ]);

  useEffect(() => {
    if (shouldOpenFileDetail) {
      pause();
    }
  }, [pause, shouldOpenFileDetail]);

  useEffect(() => {
    if (groupFolders) {
      resume();
    }
  }, [resume, groupFolders]);

  useEffect(() => {
    if (!isOnboarding) {
      return;
    }
    if (shouldOpenFileDetail && files) {
      const file = files?.filter(({ type }) => type !== 'folder').at(0);
      if (file) {
        onFileClick?.(file);
      }
    }
  }, [files, isOnboarding, onFileClick, shouldOpenFileDetail]);
  /* v8 ignore stop */

  useEffect(() => {
    forceUpdate();
  }, []);

  const handleFirstFolderClick = (folder: T) => {
    onFolderClick?.(folder);
    advanceStep(1, 0);
  };

  const handleFirstGroupFolderClick = (groupFolder: GroupFolderType) => {
    onGroupFolderClick?.(groupFolder);
    advanceStep(7, 0);
  };

  const handleFirstFileClick = (file: T) => {
    onFileClick?.(file);
    advanceStep(4, 0);
  };

  const handleFileMove = (elements: MoveableElements) => {
    onFileMove?.(elements);
    advanceStep();
  };

  return (
    <div className="gap-y-md flex flex-1 flex-col" ref={contextAnchor}>
      {files?.some(({ type }) => type === 'folder') ||
      (groupFolders && groupFolders.length > 0) ? (
        <motion.div layout className="grid-cols-file-list grid gap-8">
          <AnimatePresence>
            {files
              ?.filter(({ type }) => type === 'folder')
              .map((file, index) =>
                index === 0 ? (
                  <FolderCard
                    tourId="documents-step-1"
                    key={file.id}
                    onClick={handleFirstFolderClick}
                    onFileMove={onFileMove}
                    onFolderHover={onFolderHover}
                    folder={file}
                  />
                ) : (
                  <FolderCard
                    onClick={onFolderClick}
                    onFileMove={onFileMove}
                    onFolderHover={onFolderHover}
                    folder={file}
                    key={file.id}
                  />
                )
              )}
            {groupFolders?.map((groupFolder, index) =>
              index === 0 ? (
                <span data-tour="groups-step-1" key={groupFolder.id}>
                  <GroupFolder
                    onClick={handleFirstGroupFolderClick}
                    folder={groupFolder}
                  />
                </span>
              ) : (
                <GroupFolder
                  onClick={onGroupFolderClick}
                  folder={groupFolder}
                  key={groupFolder.id}
                />
              )
            )}
          </AnimatePresence>
        </motion.div>
      ) : null}
      <motion.div layout className="grid-cols-file-list grid gap-8">
        <AnimatePresence>
          {files
            ?.filter(({ type }) => type !== 'folder')
            .map((file, index) =>
              index === 0 ? (
                <FileCard
                  tourId="preview-document-step-1"
                  key={file.id}
                  onFolderHover={onFolderHover}
                  onFileMove={handleFileMove}
                  file={file}
                  onFileClick={handleFirstFileClick}
                  selected={selectedFilesId.some(
                    (selectedFileId) => selectedFileId === file.id
                  )}
                  onFileDoubleClick={onFileDoubleClick}
                />
              ) : (
                <FileCard
                  onFolderHover={onFolderHover}
                  onFileMove={handleFileMove}
                  file={file}
                  onFileClick={onFileClick}
                  key={file.id}
                  selected={selectedFilesId.some(
                    (selectedFileId) => selectedFileId === file.id
                  )}
                  onFileDoubleClick={onFileDoubleClick}
                />
              )
            )}
        </AnimatePresence>
      </motion.div>
      <OrganizationAcl>
        <FileListContextMenu anchorElement={contextAnchor.current} />
      </OrganizationAcl>
    </div>
  );
};

export default FileList;
