import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useOrganizationApi from '@app/services/organizations/useOrganizationApi';

const useOrganizationUsers = (organizationId: string, page: number) => {
  const { getUsers } = useOrganizationApi();

  const query = useQuery({
    queryFn: () => getUsers(organizationId, page),
    queryKey: ['organization-users', organizationId, page],
    placeholderData: keepPreviousData,
  });

  return query;
};

export default useOrganizationUsers;
