import { Typography, ListItem } from '@mui/material';

import useFileDetails from '@app/hooks/useFileDetails';
import FileIcon from '@components/file-icon';

interface BulkEditReviewItemProps {
  fileId: string;
}

const BulkEditReviewItem = ({ fileId }: BulkEditReviewItemProps) => {
  const { data: file } = useFileDetails(fileId);

  return (
    <ListItem key={fileId} className="!px-md !py-sm !items-center">
      <FileIcon
        width={26}
        height={26}
        className="mr-md"
        type={file?.type ?? 'pdf'}
      />
      <Typography>{file?.fileName ?? fileId}</Typography>
    </ListItem>
  );
};

export default BulkEditReviewItem;
