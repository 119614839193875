import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useRemoveUserFromGroup = () => {
  const { removeUser } = useGroupsApi();

  const mutation = useMutation({
    mutationFn: removeUser,
    mutationKey: ['group-removeUser'],
  });

  return mutation;
};

export default useRemoveUserFromGroup;
