import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useUpdateFilePermissions = () => {
  const { updateFilePermissions } = useGroupsApi();

  const mutation = useMutation({
    mutationFn: updateFilePermissions,
    mutationKey: ['updateFilePermissions'],
  });

  return mutation;
};

export default useUpdateFilePermissions;
