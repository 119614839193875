import {
  GetTokenSilentlyOptions,
  MissingRefreshTokenError,
} from '@auth0/auth0-react';
import axios, { AxiosError, AxiosInstance, HttpStatusCode } from 'axios';

import { configs } from '@filot/auth';
import { Range } from '@filot/types/api';

let instance: AxiosInstance | null = null;

export const getInstance = () => {
  if (instance) {
    return instance;
  }

  instance = axios.create();
  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err instanceof AxiosError) {
        if (err.status === HttpStatusCode.Forbidden && err.config) {
          return instance?.(err.config);
        }
        if (err.status && err.status > 500) {
          return Promise.reject(err);
        }

        return Promise.reject(
          new AxiosError(
            'Client Axios Error',
            err.code,
            err.config,
            err.request,
            err.response
          )
        );
      }
    }
  );

  return instance;
};

export const getRangedAccessToken = async (
  range: Range,
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>
) => {
  try {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: configs[range].audience,
        scope: 'offline_access',
      },
    });

    return accessToken;
  } catch (e) {
    if (e instanceof MissingRefreshTokenError) {
      return window.auth.getAccessToken(range);
    }
  }
};
