import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useDeleteFilePreview = () => {
  const { deleteFilePreview } = useFilesApi();
  const mutation = useMutation({
    mutationFn: deleteFilePreview,
    mutationKey: ['file-preview-delete'],
  });

  return mutation;
};

export default useDeleteFilePreview;
