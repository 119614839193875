import { useAuth0 } from '@auth0/auth0-react';

import { getEnv } from '@app/config/env';
import * as Colors from '@app/utils/colors';

import { Tag } from '../files/types';
import { fileResponseToFile, fileToFileResponse } from '../files/useFilesApi';

import getTagsMock from './mock';
import getTagsService from './service';
import { TagResponse, TagsApi, TagWithFiles } from './types';

const useTagsApi = (): TagsApi => {
  const { getAccessTokenSilently } = useAuth0();

  if (getEnv().mockApiCalls) {
    return getTagsMock();
  }

  return getTagsService({ getAccessTokenSilently });
};

const tagToTagResponse = (tag: Tag | TagWithFiles): TagResponse => ({
  name: tag.name,
  description: tag.description,
  styling: {
    background_color: tag.backgroundColor,
    text_color: tag.textColor,
  },
  id: tag.id,
  organization_id: 'organizationId',
  files_count: 'files' in tag ? tag.files.length : 0,
  files:
    'files' in tag ? tag.files.map((file) => fileToFileResponse(file)) : [],
  latest_use: '2024-10-23',
});

const tagResponseToTag = (tagResponse: TagResponse): TagWithFiles => {
  const { color, contrastColor } = Colors.stringToColor(tagResponse.name);

  return {
    id: tagResponse.id,
    name: tagResponse.name,
    backgroundColor: tagResponse.styling?.background_color ?? color,
    description: tagResponse.description,
    textColor: tagResponse.styling?.text_color ?? contrastColor,
    files: tagResponse.files.map((file) => fileResponseToFile(file)),
  };
};

export { tagToTagResponse, tagResponseToTag };

export default useTagsApi;
