import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useInviteToGroup = () => {
  const { inviteUsers } = useGroupsApi();

  const mutation = useMutation({
    mutationFn: inviteUsers,
    mutationKey: ['groups-invite-users'],
  });

  return mutation;
};

export default useInviteToGroup;
