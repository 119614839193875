import { Button } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '@components/loading';

import BulkEditFilesTable from './bulk-edit-files-table';
import BulkEditHeader from './bulk-edit-header';

interface BulkEditSelectFilesProps {
  onNext: (files: string[]) => void;
  onBack: () => void;
  fileIds: string[] | undefined;
  isLoading: boolean;
  isPlaceholderData: boolean;
  page: number;
  onPageChange: (page: number) => void;
  selectedFiles: string[];
  onFilesSelected: Dispatch<SetStateAction<string[]>>;
  onSelectAll: (selected: boolean) => void;
  allSelected: boolean;
}

const BulkEditSelectFiles = ({
  onNext,
  onBack,
  fileIds,
  isLoading,
  isPlaceholderData,
  page,
  onPageChange,
  onFilesSelected,
  selectedFiles,
  onSelectAll,
  allSelected,
}: BulkEditSelectFilesProps) => {
  const { t } = useTranslation();

  const handleSelectPage = (value: boolean) => {
    onSelectAll(false);
    if (value) {
      onFilesSelected(fileIds ?? []);
    } else {
      onFilesSelected([]);
    }
  };

  const handleSelectAll = (value: boolean) => {
    if (value) {
      onSelectAll(true);
    } else {
      onSelectAll(false);
      onFilesSelected([]);
    }
  };

  const handleRowClick = (fileId: string) => {
    onSelectAll(false);
    if (selectedFiles.some((selectedFile) => selectedFile === fileId)) {
      onFilesSelected(
        selectedFiles.filter((selectedFile) => selectedFile !== fileId)
      );
    } else {
      onFilesSelected((previousValue) => [...previousValue, fileId]);
    }
  };

  if (isLoading) {
    return (
      <div className="flex h-full flex-1 items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <BulkEditHeader
        onBack={onBack}
        title={t('groups.bulkEditing.selectFiles.title')}
        onSelectAll={handleSelectAll}
        allSelected={allSelected}
      />
      <BulkEditFilesTable
        fileIds={fileIds!}
        isPlaceholderData={isPlaceholderData}
        onPageChange={onPageChange}
        onSelectPage={handleSelectPage}
        onRowClick={handleRowClick}
        page={page}
        selected={selectedFiles}
        total={60}
        allSelected={allSelected}
      />
      <div className="flex flex-1 justify-end">
        <Button
          disabled={selectedFiles.length <= 0 && !allSelected}
          onClick={() => onNext(selectedFiles)}
          variant="contained"
          className="!my-sm !px-lg"
        >
          {t('groups.bulkEditing.selectFiles.next')}
        </Button>
      </div>
    </div>
  );
};

export default BulkEditSelectFiles;
