import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useRenameFile = () => {
  const { renameFile } = useFilesApi();

  const query = useMutation({
    mutationFn: renameFile,
    mutationKey: ['renameFile'],
  });

  return query;
};

export default useRenameFile;
