import { useMutation } from '@tanstack/react-query';

import useTagsApi from '@app/services/tags/useTagsApi';

const useRemoveTag = () => {
  const { removeTag } = useTagsApi();
  const query = useMutation({
    mutationFn: removeTag,
    mutationKey: ['tag-remove'],
  });

  return query;
};

export default useRemoveTag;
