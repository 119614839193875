import { useQuery } from '@tanstack/react-query';

import { File } from '@app/services/files/types';
import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';

const useFolderDetails = (folderId: string) => {
  const { getFolderDetails } = useFilesApi();

  const query = useQuery({
    queryFn: () => getFolderDetails(folderId),
    queryKey: ['folder-details', folderId],
    select: (data) => {
      const folder: File | undefined = data
        ? fileResponseToFile(data.data)
        : undefined;

      return folder;
    },
  });

  return query;
};

export default useFolderDetails;
