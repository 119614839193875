import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useAddFiles = () => {
  const { addFiles } = useGroupsApi();

  const mutation = useMutation({
    mutationFn: addFiles,
    mutationKey: ['groups-add-files'],
  });

  return mutation;
};

export default useAddFiles;
