import { useAuth0 } from '@auth0/auth0-react';
import { Autocomplete, TextField } from '@mui/material';
import {
  ChangeEvent,
  KeyboardEventHandler,
  SyntheticEvent,
  forwardRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ShareUserOrGroup } from '@app/services/files/types';
import { Group } from '@app/services/groups/types';
import { useUserInputContext } from '@pages/group-members/hooks/useUserInputContext';
import useInfiniteGroups from '@pages/groups/hooks/useInfiniteGroups';

import UserInputEmailChip from './user-input-email-chip';

interface GroupsUserInputProps {
  value: ShareUserOrGroup[];
  onChange: (newValue: ShareUserOrGroup[]) => void;
  onUserFromOutside: () => void;
}

const getLabel = (value: ShareUserOrGroup) =>
  typeof value === 'string' ? value : value.label;

const GroupsUserInput = forwardRef<HTMLInputElement, GroupsUserInputProps>(
  ({ onChange, value, onUserFromOutside }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const { user } = useAuth0();

    const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
      useInfiniteGroups(user?.sub);

    const { setIsAvailable } = useUserInputContext();

    const { t } = useTranslation();

    const handleOpen = () => {
      setOpen(true);
    };

    const handleGroupSelection = (
      e: SyntheticEvent<Element, Event>,
      group: Group | null
    ) => {
      e.preventDefault();
      if (group && !value.includes(group.name)) {
        onChange([...value, { label: group.name, id: group.id }]);
        setInputValue('');
        setError(false);
        setOpen(false);
      }
    };

    const handleDelete = (userToDelete: string) => {
      onChange(value.filter((userInInput) => userInInput !== userToDelete));
    };

    /* v8 ignore start */
    const handleScroll = async (event: React.UIEvent<HTMLUListElement>) => {
      const bottomReached =
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop <=
        event.currentTarget.clientHeight + 50;
      if (bottomReached) {
        if (!isFetching && hasNextPage) {
          await fetchNextPage();
        }
      }
    };
    /* v8 ignore end */

    const handleKeyDown: KeyboardEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (event) => {
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

      if (event.key === 'Enter' && inputValue.trim()) {
        event.preventDefault();
        if (
          emailRegex.test(inputValue.trim()) &&
          !value.includes(inputValue.trim())
        ) {
          setIsAvailable(false);
          onChange([...value, inputValue.trim()]);
          setInputValue('');
          setError(false);
          setOpen(false);
        } else {
          setError(true);
          setOpen(false);
        }
      } else if (event.key === 'Backspace' && inputValue === '') {
        event.preventDefault();
        setInputValue(getLabel(value.at(-1) ?? ''));
        onChange(value.slice(0, -1));
      }
    };

    return (
      <Autocomplete
        disablePortal
        id="groups-selection"
        options={data?.groups ?? []}
        getOptionLabel={(option) => option.name}
        onChange={(e, group) => handleGroupSelection(e, group)}
        ListboxProps={{
          style: { maxHeight: 300 },
          onScroll: handleScroll,
        }}
        loading={isLoading}
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            ref={ref}
            label={t('components.groupsUserInput.label')}
            helperText={t('components.groupsUserInput.message')}
            variant="standard"
            error={error}
            className="!mb-md flex-wrap"
            inputProps={{
              ...params.inputProps,
              onKeyDown: handleKeyDown,
              value: inputValue,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                params.inputProps.onChange?.(e);
                setInputValue(e.currentTarget.value);
              },
            }}
            InputProps={{
              ...params.InputProps,
              className: '!w-none',
              startAdornment: (
                <div
                  className="my-sm flex flex-1 flex-wrap self-start"
                  data-testid="users-input-chips"
                >
                  {value.map((chip) => (
                    <UserInputEmailChip
                      key={getLabel(chip)}
                      label={getLabel(chip)}
                      onDelete={handleDelete}
                      onUserFromOutside={onUserFromOutside}
                    />
                  ))}
                </div>
              ),
            }}
          />
        )}
      />
    );
  }
);

export default GroupsUserInput;
