import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useDownloadFile = () => {
  const { download } = useFilesApi();

  const query = useMutation({
    mutationFn: download,
    mutationKey: ['file-download'],
  });

  return query;
};

export default useDownloadFile;
