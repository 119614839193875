import { useTheme } from '@mui/material';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import {
  ChangeEventHandler,
  DragEventHandler,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';

import useAdvancedSearch from '@app/hooks/useAdvancedSearch';
import useAlert from '@app/hooks/useAlert';
import useAppJoyride from '@app/hooks/useAppJoyride';
import { useBreadcrumb } from '@app/hooks/useBreadcrumb';
import usePathnameToBreadcrumb from '@app/hooks/usePathnameToBreadcrumb';
import { useSearch, useSetSearch } from '@app/hooks/useSearch';
import { GroupFolder } from '@app/services/files/types';
import {
  AdvancedSearchFormInputs,
  File,
  FileToUpload,
} from '@app/services/files/types';
import CheckIcon from '@assets/layout/check.svg?react';
import { MoveableElements } from '@pages/files/hooks/useMoveableToFolder';

import AdvancedSearchModal from './components/advanced-search-modal';
import Header from './components/header';
import Navigator from './components/navigator';
import UploadPreviewModal from './components/upload-preview-modal';
import useDragFiles from './hooks/useDragFiles';
import useFiles from './hooks/useFiles';
import useSort, { UseSortResult } from './hooks/useSort';
import useUploadFile from './hooks/useUploadFile';

type ContextType = {
  filesQuery: {
    files?: File[];
    groupFolders?: GroupFolder[];
    isLoading: boolean;
    isError: boolean;
    refetch: (options?: RefetchOptions) => Promise<
      QueryObserverResult<
        {
          files: File[];
          groupFolders: GroupFolder[];
        },
        Error
      >
    >;
  };
  onNavigate: (elements: MoveableElements) => void;
  onFileMove: (elements: MoveableElements) => void;
  onDrop: DragEventHandler<HTMLSpanElement>;
  sort: UseSortResult;
};

const Layout = () => {
  const search = useSearch();
  const setSearch = useSetSearch();
  const [isAdvancedSearchModalVisible, setIsAdvancedSearchModalVisible] =
    useState(false);
  const { toString } = useAdvancedSearch();
  const { folders } = useBreadcrumb();
  const navigate = useNavigate();
  const location = useLocation();
  const { parse } = useAdvancedSearch();
  const sort = useSort();

  const {
    data,
    isLoading,
    refetch,
    isError: isFilesError,
  } = useFiles({
    filters: parse(search),
    path: folders.join('/'),
    sortBy: sort.sort,
    sortDirection: sort.direction,
  });
  const [documentToUpload, setDocumentToUpload] = useState<Omit<
    FileToUpload,
    'location' | 'tags'
  > | null>(null);
  const [uploadedFile, setUploadedFile] = useState<null | globalThis.File>(
    null
  );
  const [isUploadPreviewModalOpen, setIsUploadPreviewModalOpen] =
    useState(false);
  const { onFileMove, onNavigate, DraggingFile } = useDragFiles({
    refetch: async () => {
      await refetch();
    },
    files: data?.files,
  });
  const {
    mutateAsync: uploadFile,
    isPending,
    isError,
    reset,
  } = useUploadFile();
  const { display, Alert } = useAlert();
  const { palette } = useTheme();
  const { t } = useTranslation();
  usePathnameToBreadcrumb();
  const { start } = useAppJoyride();

  useEffect(() => {
    if (
      location.pathname === '/' ||
      (search === '' && location.pathname.startsWith('/files')) ||
      (search !== '' && !search.includes('path'))
    ) {
      if (location.pathname.startsWith('/tags')) {
        return;
      }
      navigate('/files');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, search]);

  useEffect(() => {
    void start();
  }, [start]);

  const handleAdvancedSearch: SubmitHandler<AdvancedSearchFormInputs> = (
    params
  ) => {
    setSearch(toString(params));
  };

  const handleSearchChange = (value: string) => {
    const lastFolder = folders.at(-1);
    if (lastFolder && !value.includes(lastFolder) && value !== '') {
      setSearch(`path:"${folders.join('/')}" ${value}`);
    } else {
      setSearch(value);
    }
  };

  const handleOpenFile: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      setIsUploadPreviewModalOpen(true);
      [...event.target.files].forEach((file) => {
        setUploadedFile(file);
        setDocumentToUpload({
          previewUri: window.URL.createObjectURL(file),
          name: file.name,
          mimeType: file.type,
        });
      });
    }
  };

  const handleUpload = async (document: Omit<FileToUpload, 'location'>) => {
    const match = location.pathname.match(/^\/files(\/.*)?$/);
    const currentLocation = match?.at(1) ?? '';
    if (uploadedFile) {
      await uploadFile({
        name: document.name,
        path: `${currentLocation}/${document.name}`,
        file: uploadedFile,
      });
      setIsUploadPreviewModalOpen(false);
      display();
    }
  };

  const handleDrop: DragEventHandler<HTMLSpanElement> = (event) => {
    event.preventDefault();
    setIsUploadPreviewModalOpen(true);
    [...event.dataTransfer.files].forEach((file) => {
      setUploadedFile(file);
      setDocumentToUpload({
        previewUri: window.URL.createObjectURL(file),
        name: file.name,
        mimeType: file.type,
      });
    });
  };

  const handleModalClose = () => {
    reset();
    setIsUploadPreviewModalOpen(false);
  };

  return (
    <>
      <Header
        searchBar
        onAdvancedSearchClick={() => setIsAdvancedSearchModalVisible(true)}
        onSearchChange={handleSearchChange}
      />
      <Alert icon={<CheckIcon color={palette.success.main} />}>
        {t('layout.uploadPreview.success')}
      </Alert>
      <div className="flex flex-1 overflow-y-auto">
        <Navigator onUploadClick={handleOpenFile} />
        <Outlet
          context={
            {
              filesQuery: {
                files: data?.files,
                groupFolders: data?.groupFolders,
                isLoading,
                isError: isFilesError,
                refetch,
              },
              onNavigate,
              onFileMove,
              sort,
              onDrop: handleDrop,
            } satisfies ContextType
          }
        />
      </div>
      <AdvancedSearchModal
        isOpen={isAdvancedSearchModalVisible}
        onClose={() => setIsAdvancedSearchModalVisible(false)}
        onSearch={handleAdvancedSearch}
      />
      <DraggingFile />
      <UploadPreviewModal
        onTryAgain={reset}
        onUpload={handleUpload}
        isError={isError}
        document={documentToUpload}
        onClose={handleModalClose}
        isOpen={isUploadPreviewModalOpen}
        isPending={isPending}
      />
    </>
  );
};

export const useLayout = () => useOutletContext<ContextType>();

export default Layout;
