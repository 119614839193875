import { useMutation } from '@tanstack/react-query';

import useOrganizationApi from '@app/services/organizations/useOrganizationApi';

const useInviteToOrganization = () => {
  const { inviteUser } = useOrganizationApi();

  const mutation = useMutation({
    mutationFn: inviteUser,
    mutationKey: ['organization-invite-user'],
  });

  return mutation;
};

export default useInviteToOrganization;
