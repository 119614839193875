import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useLeaveGroup = () => {
  const { leaveGroup } = useGroupsApi();
  const mutation = useMutation({
    mutationFn: leaveGroup,
    mutationKey: ['groups-leave-group'],
  });

  return mutation;
};

export default useLeaveGroup;
