import { Button } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '@components/logo';

const LoginRedirect = () => {
  const { t } = useTranslation();
  const link = useRef<null | HTMLAnchorElement>(null);
  const params = window.location.href;

  const redirectToApp = () => {
    link.current?.click();
  };

  useEffect(() => {
    redirectToApp();
  }, []);

  return (
    <div className="mt-[20vh] flex flex-col items-center">
      <Logo />
      <p className="mt-md text-2xl font-medium">{t('loginRedirect.title')}</p>
      <p className="mt-sm text-lg">{t('loginRedirect.text')}</p>
      <Button
        onClick={redirectToApp}
        variant="contained"
        className="!mt-lg !px-lg"
      >
        {t('loginRedirect.button')}
      </Button>
      <a
        ref={link}
        className="hidden"
        href={`filot://login?${params.split('?').at(1) ?? ''}`}
      />
    </div>
  );
};

export default LoginRedirect;
