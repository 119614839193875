import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useCreateFolder = () => {
  const { createFolder } = useFilesApi();
  const query = useMutation({
    mutationFn: createFolder,
    mutationKey: ['createFolder'],
  });

  return query;
};

export default useCreateFolder;
