import { PropsWithChildren, useMemo } from 'react';

import { isRunningOnElectron } from '@app/utils/environment';

interface PlatformOnlyProps {
  platform: 'app' | 'web';
  visible?: boolean;
}

const PlatformOnly = ({
  children,
  platform,
  visible = true,
}: PropsWithChildren<PlatformOnlyProps>) => {
  const isApp = useMemo(() => isRunningOnElectron(), []);

  if (!visible) {
    return null;
  }

  if (isApp && platform === 'app') {
    return children;
  } else if (!isApp && platform === 'web') {
    return children;
  }

  return null;
};

export default PlatformOnly;
