import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useMoveFile = () => {
  const { moveFile } = useFilesApi();

  const query = useMutation({
    mutationFn: moveFile,
    mutationKey: ['moveFile'],
  });

  return query;
};

export default useMoveFile;
