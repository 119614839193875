import { useQuery } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useFilePreview = (fileId: string) => {
  const { getFilePreview } = useFilesApi();

  const query = useQuery({
    queryFn: () => getFilePreview(fileId),
    queryKey: ['file-preview', fileId],
    select: ({ data }) => data,
  });

  return query;
};

export default useFilePreview;
