import { useMutation } from '@tanstack/react-query';

import useNotesApi from '@app/services/notes/useNotesApi';

const useCreateNote = () => {
  const { createNote } = useNotesApi();
  const query = useMutation({
    mutationFn: createNote,
    mutationKey: ['create-note'],
  });

  return query;
};

export default useCreateNote;
