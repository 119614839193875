import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useErrorHandler from '@app/hooks/useErrorHandler';
import { FileAccessLevel } from '@app/services/files/types';
import { File } from '@app/services/groups/types';

import BulkEditReview from './components/bulk-edit-review';
import BulkEditSelectFiles from './components/bulk-edit-select-files';
import BulkEditSetPermissions from './components/bulk-edit-set-permissions';
import useBulkEdit from './hooks/useBulkEdit';
import useGroupFiles from './hooks/useGroupFiles';

const GroupBulkEditing = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();

  const [currentStep, setCurrentStep] = useState<1 | 2 | 'review'>(1);
  const [allSelected, setAllSelected] = useState(false);
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<File['permissions'] | null>(
    null
  );
  const [page, setPage] = useState(0);
  const { data, isLoading, isPlaceholderData } = useGroupFiles({
    groupId: groupId ?? '',
    page,
  });
  const { isPending, mutateAsync, isError, reset, isSuccess } = useBulkEdit();
  useErrorHandler(isError);

  const handleFileSelection = () => {
    setCurrentStep(2);
  };

  const handlePermissionsSet = (newPermissions: File['permissions']) => {
    setPermissions(newPermissions);
    setCurrentStep('review');
  };

  const handleSubmit = async () => {
    if (groupId && permissions) {
      await mutateAsync({
        fileIds,
        groupId,
        permissions: (Object.keys(permissions) as FileAccessLevel[]).filter(
          (key) => permissions[key]
        ),
      });
    }
  };

  if (!groupId) {
    navigate('/files');

    return;
  }

  const Content = () => {
    if (currentStep === 1) {
      return (
        <BulkEditSelectFiles
          onBack={() => navigate(-1)}
          onNext={handleFileSelection}
          onFilesSelected={setFileIds}
          selectedFiles={fileIds}
          fileIds={data}
          isLoading={isLoading}
          isPlaceholderData={isPlaceholderData}
          onPageChange={setPage}
          page={page}
          allSelected={allSelected}
          onSelectAll={setAllSelected}
        />
      );
    }
    if (currentStep === 2) {
      return (
        <BulkEditSetPermissions
          onBack={() => setCurrentStep(1)}
          onNext={handlePermissionsSet}
        />
      );
    }
    if (currentStep === 'review' && permissions) {
      return (
        <BulkEditReview
          onBack={() => setCurrentStep(2)}
          fileIds={fileIds}
          permissions={permissions}
          isError={isError}
          isPending={isPending}
          onSubmit={handleSubmit}
          allSelected={allSelected}
          onTryAgain={reset}
          isSuccess={isSuccess}
        />
      );
    }
  };

  return (
    <div className="px-screen py-lg w-full overflow-y-auto">
      <Content />
    </div>
  );
};

export default GroupBulkEditing;
