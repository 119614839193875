import { Button, Typography, List } from '@mui/material';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { FileAccessLevel } from '@app/services/files/types';
import { File } from '@app/services/groups/types';
import ErrorIcon from '@assets/groups/bulk-edit-error.svg?react';
import EditIcon from '@assets/groups/edit.svg?react';
import LoadingIcon from '@assets/groups/loading.svg';
import SuccessIcon from '@assets/shared/success.svg?react';
import ErrorHelpReport from '@components/error-help-report';

import BulkEditHeader from './bulk-edit-header';
import BulkEditReviewItem from './bulk-edit-review-item';

interface BulkEditReviewProps {
  fileIds: string[];
  permissions: File['permissions'];
  onSubmit: () => void;
  onBack: () => void;
  isError: boolean;
  isPending: boolean;
  onTryAgain: () => void;
  allSelected: boolean;
  isSuccess: boolean;
}

const BulkEditReview = ({
  fileIds,
  onSubmit,
  permissions,
  onBack,
  isError,
  isPending,
  onTryAgain,
  allSelected,
  isSuccess,
}: BulkEditReviewProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();

  const permissionsString = useMemo(() => {
    const permissionsConceded: Array<FileAccessLevel | 'noPermissions'> = [];
    if (permissions.read) {
      permissionsConceded.push('read');
    }
    if (permissions.write) {
      permissionsConceded.push('write');
    }
    if (permissions.read_notes) {
      permissionsConceded.push('read_notes');
    }
    if (permissions.create_notes) {
      permissionsConceded.push('create_notes');
    }
    if (permissions.update_permissions) {
      permissionsConceded.push('update_permissions');
    }
    if (permissions.delete) {
      permissionsConceded.push('delete');
    }
    if (permissionsConceded.length === 0) {
      permissionsConceded.push('noPermissions');
    }

    return permissionsConceded
      .map((permission) =>
        t('groups.bulkEditing.review.permissions.permissions', {
          context: permission,
        })
      )
      .join(', ');
  }, [
    permissions.create_notes,
    permissions.delete,
    permissions.read,
    permissions.read_notes,
    permissions.update_permissions,
    permissions.write,
    t,
  ]);

  const Content = () => {
    if (isError) {
      return (
        <div className="flex h-[50vh] flex-1 flex-col items-center justify-center">
          <ErrorIcon />
          <Typography className="!mt-24 !text-lg !font-medium">
            <Trans i18nKey="groups.bulkEditing.review.error">
              Oops! There's was an error updating the group.
              <span className="cursor-pointer underline" onClick={onTryAgain}>
                Try again?
              </span>
            </Trans>
          </Typography>
          <ErrorHelpReport />
        </div>
      );
    }

    if (isPending) {
      return (
        <div className="mt-lg flex flex-1 flex-col items-center justify-center">
          <img width={280} height={280} src={LoadingIcon} />
          <Typography className="!mt-lg !text-2xl !font-medium">
            {t('groups.bulkEditing.review.loading')}
          </Typography>
        </div>
      );
    }

    if (isSuccess) {
      return (
        <div className="mt-44 flex flex-col items-center justify-center">
          <SuccessIcon />
          <Typography className="!mt-md !text-2xl !font-medium">
            {t('groups.bulkEditing.review.success.title', {
              count: fileIds.length,
            })}
          </Typography>
          <div className="gap-x-md mt-md flex">
            <Button
              variant="outlined"
              onClick={() => navigate(`/groups/${groupId}/files`)}
            >
              {t('groups.bulkEditing.review.success.manageFiles')}
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(`/groups/${groupId}`)}
            >
              {t('groups.bulkEditing.review.success.group')}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <>
        {allSelected ? null : (
          <List className="!mt-lg max-h-[30vh] overflow-y-auto border border-gray-300">
            {fileIds.map((id) => (
              <BulkEditReviewItem key={id} fileId={id} />
            ))}
          </List>
        )}
        <div className="my-md flex flex-1 justify-end">
          <Button variant="contained" className="!px-lg" onClick={onSubmit}>
            {t('groups.bulkEditing.review.finish')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div>
      <BulkEditHeader
        onBack={onBack}
        title={t('groups.bulkEditing.review.title')}
      />
      <div className="!mt-lg flex items-center">
        <EditIcon width={20} height={20} />
        <Typography className="!pl-md !text-2xl" data-testid="review-title">
          {allSelected ? (
            <Trans
              i18nKey="groups.bulkEditing.review.editing_all"
              count={fileIds.length}
            >
              Editing
              <span className="!text-3xl !font-bold">all</span>
              file to have
              <span className="!text-3xl !font-bold">
                <>{{ permissions: permissionsString }}</>
              </span>
              permissions
            </Trans>
          ) : (
            <Trans
              i18nKey="groups.bulkEditing.review.editing"
              count={fileIds.length}
            >
              Editing
              <span className="!text-3xl !font-bold">
                <>{{ count: fileIds.length }}</>
              </span>
              file to have
              <span className="!text-3xl !font-bold">
                <>{{ permissions: permissionsString }}</>
              </span>
              permissions
            </Trans>
          )}
        </Typography>
      </div>
      <Content />
    </div>
  );
};

export default BulkEditReview;
