import { useMutation } from '@tanstack/react-query';

import useTagsApi from '@app/services/tags/useTagsApi';

const useDeleteTag = () => {
  const { deleteTag } = useTagsApi();
  const query = useMutation({
    mutationFn: deleteTag,
    mutationKey: ['tag-delete'],
  });

  return query;
};

export default useDeleteTag;
