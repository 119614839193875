import { useAuth0 } from '@auth0/auth0-react';

import { getEnv } from '@app/config/env';

import getOrganizationMock from './mock';
import getOrganizationService from './service';
import { OrganizationApi } from './types';

const useOrganizationApi = (): OrganizationApi => {
  const { getAccessTokenSilently } = useAuth0();

  if (getEnv().mockApiCalls) {
    return getOrganizationMock();
  }

  return getOrganizationService({ getAccessTokenSilently });
};

export default useOrganizationApi;
