import { useMutation } from '@tanstack/react-query';

import useOrganizationApi from '@app/services/organizations/useOrganizationApi';

const useTransferOrganizationOwnership = () => {
  const { transferOwnership } = useOrganizationApi();
  const mutation = useMutation({
    mutationFn: transferOwnership,
    mutationKey: ['organization-transfer-ownership'],
  });

  return mutation;
};

export default useTransferOrganizationOwnership;
