import { useQueryClient } from '@tanstack/react-query';
import { ChangeEventHandler, createContext, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useAlert from '@app/hooks/useAlert';
import useErrorHandler from '@app/hooks/useErrorHandler';
import useSafeValue from '@app/hooks/useSafeValues';
import { Group } from '@app/services/groups/types';
import BackArrow from '@assets/groups/back_arrow.svg?react';
import ErrorIcon from '@assets/shared/error.svg?react';
import EditableText from '@components/editable-text';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';
import UpdateAvatar from '@components/update-avatar';
import useGroupMembers from '@pages/group-members/hooks/useGroupMembers';

import GroupDangerZone from './components/group-danger-zone';
import GroupDetailsEditDates from './components/group-details-edit-dates';
import GroupDetailsTable from './components/group-details-table';
import useGroupDetails from './hooks/useGroupDetails';
import useUpdateGroupDetails from './hooks/useUpdateGroupDetails';
import useUpdateGroupPicture from './hooks/useUpdateGroupPicture';

export const GroupDetailsContext = createContext<Group | null>(null);

const GroupDetails = () => {
  const { groupId: id = '' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    data: groupDetails,
    isLoading: isLoadingDetails,
    isError: isErrorDetails,
    refetch: refetchDetails,
  } = useGroupDetails(id);
  const {
    data: groupMembers,
    isLoading: isLoadingMembers,
    isError: isErrorMembers,
    refetch: refetchMembers,
  } = useGroupMembers({
    groupId: id,
    page: 1,
  });
  const { mutateAsync: updateGroupDetails, isPending } =
    useUpdateGroupDetails();
  const {
    mutateAsync: updateGroupPicture,
    isPending: isUpdatingPicture,
    isError,
  } = useUpdateGroupPicture();
  const { Alert, display } = useAlert();
  const {
    currentValue: currentName,
    resetToSafeValue: resetToSafeName,
    updateCurrentValue: updateCurrentName,
    updateSafeValue: updateSafeName,
  } = useSafeValue(groupDetails?.name ?? '');
  const {
    currentValue: currentDescription,
    resetToSafeValue: resetToSafeDescription,
    updateCurrentValue: updateCurrentDescription,
    updateSafeValue: updateSafeDescription,
  } = useSafeValue(groupDetails?.description);

  useErrorHandler(isError, {
    callback: () => {
      display();
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  const handleEdit = useCallback(
    async ({ name, value }: { name: string; value: string }) => {
      if (name === 'description') {
        updateCurrentDescription(value);
      } else {
        updateCurrentName(value);
      }
      try {
        await updateGroupDetails({
          groupId: id,
          ...{
            [name]: value,
          },
        });
        if (name === 'description') {
          updateSafeDescription(value);
        } else {
          updateSafeName(value);
        }
      } catch (error) {
        resetToSafeName();
        resetToSafeDescription();
      }
    },
    [
      id,
      resetToSafeDescription,
      resetToSafeName,
      updateCurrentDescription,
      updateCurrentName,
      updateGroupDetails,
      updateSafeDescription,
      updateSafeName,
    ]
  );

  const refetchAll = useCallback(() => {
    void refetchMembers();
    void refetchDetails();
  }, [refetchDetails, refetchMembers]);

  const handleOpenFile: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.target.files) {
        [...event.target.files].forEach((file) => {
          void (async () => {
            await updateGroupPicture({ file, groupId: id });
            await queryClient.invalidateQueries({
              queryKey: ['groups-update-picture'],
            });
          })();
        });
      }
    },
    [id, queryClient, updateGroupPicture]
  );

  const content = useMemo(() => {
    if (isLoadingDetails || isLoadingMembers) {
      return (
        <div className="flex h-72 flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isErrorDetails || isErrorMembers) {
      return (
        <div className="flex h-4/5 flex-1 flex-col items-center justify-center">
          <ErrorIcon />
          <p className="my-md text-xl">
            <Trans i18nKey="groups.groupDetails.error">
              Oops! There was an error loading group's details.
              <span
                className="ml-2 cursor-pointer underline"
                onClick={refetchAll}
              >
                Try again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    if (groupDetails && groupMembers) {
      return (
        <>
          <div className="mt-md gap-x-sm flex">
            <EditableText
              name="description"
              placeholder={t('groups.groupDetails.description')}
              multiline
              maxRows={4}
              readOnly={isPending}
              className="!m-0 !w-[min(100%,60rem)] !p-0 !font-normal"
              defaultValue={currentDescription ?? ''}
              onSubmit={handleEdit}
            />
            <UpdateAvatar
              picture={groupDetails.picture}
              name={groupDetails.name}
              className="!h-24 !w-24 !text-3xl"
              onUpdate={handleOpenFile}
              isLoading={isUpdatingPicture}
            />
          </div>
          <GroupDetailsTable details={groupDetails} members={groupMembers} />
          <div className="mt-lg">
            <GroupDangerZone />
          </div>
        </>
      );
    }

    return null;
  }, [
    currentDescription,
    groupDetails,
    groupMembers,
    handleEdit,
    handleOpenFile,
    isErrorDetails,
    isErrorMembers,
    isLoadingDetails,
    isLoadingMembers,
    isPending,
    isUpdatingPicture,
    refetchAll,
    t,
  ]);

  return (
    <GroupDetailsContext.Provider value={groupDetails ?? null}>
      <div className="p-screen w-full overflow-auto" data-tour="groups-step-2">
        <div className="flex items-center">
          <PressableIcon
            onClick={goBack}
            icon={<BackArrow title="back" className="text-black" />}
          />
          <EditableText
            required
            readOnly={isPending}
            name="name"
            className="!text-3xl"
            variant="h2"
            onSubmit={handleEdit}
            defaultValue={currentName}
          />
          {groupDetails && <GroupDetailsEditDates details={groupDetails} />}
        </div>
        {content}
      </div>
      <Alert severity="error">
        {t('groups.groupDetails.errorUpdatingPicture')}
      </Alert>
    </GroupDetailsContext.Provider>
  );
};

export default GroupDetails;
