import { Modal, Box, Typography, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import useCloseRequestModal from '@app/hooks/useCloseRequestModal';
import { OrganizationUserInviteRequest } from '@app/services/organizations/types';
import Error from '@assets/organizations/error.svg?react';
import Close from '@assets/shared/close.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import { useOrganizationContext } from '../hooks/useOrganizationContext';
import useUserByEmail from '../hooks/useUserByEmail';

import OrganizationNewUserInformation from './organization-new-user-information';
import OrganizationUserBulkInviteForm from './organization-user-bulk-invite-form';
import OrganizationUserInviteForm, {
  OrganizationUserInviteFormInputs,
} from './organization-user-invite-form';

interface OrganizationUserInviteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: SubmitHandler<OrganizationUserInviteRequest>;
  isInviting: boolean;
  isInviteError: boolean;
  onTryAgain: () => void;
}

const OrganizationUserInviteModal = ({
  isOpen,
  onClose,
  onInvite,
  isInviteError,
  isInviting,
  onTryAgain,
}: OrganizationUserInviteModalProps) => {
  const { t } = useTranslation();
  const organization = useOrganizationContext();

  const [newUser, setNewUser] = useState<OrganizationUserInviteRequest | null>(
    null
  );
  const [bulkEditing, setBulkEditing] = useState(false);
  const {
    data,
    refetch: fetch,
    isLoading,
    isError,
  } = useUserByEmail(newUser?.email ?? '');
  const withCanClose = useCloseRequestModal(isInviting, onTryAgain);

  useEffect(() => {
    if (newUser?.email) {
      void fetch();
    }
  }, [newUser?.email, fetch]);

  const handleClose = () => {
    setBulkEditing(false);
    setNewUser(null);
    withCanClose(onClose)();
  };

  const handleReview: SubmitHandler<OrganizationUserInviteFormInputs> = ({
    accessExpiration,
    accessGrant,
    email,
  }) => {
    const accessGrantDate = accessGrant.toISODate();
    if (accessGrantDate) {
      setNewUser({
        accessExpiration: accessExpiration?.toISODate() ?? null,
        accessGrant: accessGrantDate,
        email: `${email}@filot.io`,
        organization,
      });
    }
  };

  const handleInvite = () => {
    if (newUser) {
      onInvite(newUser);
      setNewUser(null);
    }
  };

  const Content = () => {
    if (isInviting || isLoading) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isInviteError) {
      return (
        <div className="mb-lg flex flex-1 flex-col items-center justify-center">
          <Error className="mb-lg" />
          <Typography className="!mr-1 !text-lg">
            <Trans i18nKey="organization.users.inviteUsersModal.error.inviting">
              There was an error inviting user. Please
              <span
                className="cursor-pointer text-lg underline"
                onClick={onTryAgain}
              >
                try again
              </span>
            </Trans>
          </Typography>
          <ErrorHelpReport />
        </div>
      );
    }

    if (bulkEditing) {
      return <OrganizationUserBulkInviteForm />;
    }

    if (newUser === null) {
      return (
        <OrganizationUserInviteForm
          onSubmit={handleReview}
          organizationDomain="filot.io"
        />
      );
    }

    if (isError) {
      return (
        <div className="mb-lg flex flex-1 flex-col items-center justify-center">
          <Error className="mb-lg" />
          <Typography className="!mr-1 !text-lg">
            {t(
              'organization.users.inviteUsersModal.error.gettingUserData.title'
            )}
          </Typography>
          <span
            className="mb-md cursor-pointer text-lg underline"
            onClick={() => setNewUser(null)}
          >
            Try again.
          </span>
          <ErrorHelpReport />
        </div>
      );
    }

    if (data) {
      return (
        <OrganizationNewUserInformation
          user={data}
          newUser={newUser}
          onInvite={handleInvite}
          onGoBack={() => setNewUser(null)}
        />
      );
    }
  };

  return (
    <Modal className="flex" open={isOpen} onClose={handleClose}>
      <Box className="w-tags-editor p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <div className="mb-md flex items-start justify-between">
          <Typography className="!text-2xl !font-medium">
            {t('organization.users.inviteUsersModal.form.title')}
          </Typography>
          <span className="gap-x-sm flex items-center">
            {newUser === null && !bulkEditing ? (
              <Button
                variant="outlined"
                size="medium"
                onClick={() => setBulkEditing(true)}
              >
                {t('organization.users.inviteUsersModal.bulkInviting')}
              </Button>
            ) : null}
            <PressableIcon
              onClick={handleClose}
              icon={<Close title="close" />}
              className="self-end"
            />
          </span>
        </div>
        <Content />
      </Box>
    </Modal>
  );
};

export default OrganizationUserInviteModal;
