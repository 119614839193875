import { useAuth0 } from '@auth0/auth0-react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useGroupsApi, {
  groupResponseToGroup,
} from '@app/services/groups/useGroupsApi';

const useGroupDetails = (id: string) => {
  const { getDetails } = useGroupsApi();
  const { user } = useAuth0();

  const query = useQuery({
    queryFn: () => getDetails(id),
    queryKey: ['group-details', id],
    placeholderData: keepPreviousData,
    select: (data) => groupResponseToGroup(data, user?.sub),
  });

  return query;
};

export default useGroupDetails;
