import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileAccessLevel } from '@app/services/files/types';

interface ManageAccessPeoplePersonInputProps {
  currentPermission: FileAccessLevel[];
  onChangePermission: (newPermission: FileAccessLevel[] | 'revoke') => void;
  loading: boolean;
}

const ManageAccessPeoplePersonInput = ({
  loading,
  onChangePermission,
  currentPermission,
}: ManageAccessPeoplePersonInputProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const options = useMemo<Array<{ value: FileAccessLevel; text: string }>>(
    () => [
      {
        value: 'read',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.viewer'
        ),
      },
      {
        value: 'write',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.editor'
        ),
      },
      {
        value: 'read_notes',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.readNotes'
        ),
      },
      {
        value: 'create_notes',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.createNotes'
        ),
      },
      {
        value: 'delete',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.delete'
        ),
      },
      {
        value: 'update_permissions',
        text: t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.updatePermissions'
        ),
      },
    ],
    [t]
  );

  const handleChange = (
    e: SelectChangeEvent<Array<FileAccessLevel | 'revoke'>>
  ) => {
    if (e.target.value.includes('revoke')) {
      setOpen(false);
      onChangePermission('revoke');
    } else {
      onChangePermission(e.target.value as FileAccessLevel[] | 'revoke');
    }
  };

  return (
    <Select<Array<FileAccessLevel | 'revoke'>>
      value={currentPermission}
      onChange={handleChange}
      variant="outlined"
      multiple
      sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
      disabled={loading}
      renderValue={(selected) => (
        <p className="text-wrap text-right">
          {options.filter((option) => selected.includes(option.value))[0].text}
          {selected.length > 1 ? ` +${selected.length - 1}` : ''}
        </p>
      )}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      {options.map((option) => (
        <MenuItem value={option.value} className="!py-0" key={option.value}>
          <Checkbox checked={currentPermission.includes(option.value)} />
          <ListItemText primary={option.text} />
        </MenuItem>
      ))}

      <MenuItem value="revoke" className="!pl-10">
        {t(
          'fileList.fileDetails.inviteUsersModal.form.generalAccess.revokeAccess'
        )}
      </MenuItem>
    </Select>
  );
};

export default ManageAccessPeoplePersonInput;
