import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEventHandler, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useAlert from '@app/hooks/useAlert';
import useErrorHandler from '@app/hooks/useErrorHandler';
import NewFolderIcon from '@assets/layout/new-folder.svg?react';
import UploadFileIcon from '@assets/layout/upload-file.svg?react';
import NewIcon from '@assets/shared/add.svg?react';
import OrganizationAcl from '@components/organization-acl';
import PlatformOnly from '@components/platform-only';
import NewFolderModal from '@pages/files/components/new-folder-modal';
import useCreateFolder from '@pages/files/hooks/useCreateFolder';

interface NewButtonProps {
  onFileUpload: ChangeEventHandler<HTMLInputElement>;
}

const NewButton = ({ onFileUpload }: NewButtonProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { mutateAsync, isPending, isError, reset } = useCreateFolder();
  const queryClient = useQueryClient();
  const { display, Alert } = useAlert();
  useErrorHandler(isError, {
    callback: () => {
      display();
      reset();
    },
  });

  const [isCreateFolderVisible, setIsCreateFolderVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const anchorEl = useRef<HTMLButtonElement | null>(null);

  const fileInput = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    setIsMenuVisible(false);
    if (fileInput.current) {
      fileInput.current.value = '';
    }
    document.getElementById('file-input')?.click();
  };

  const handleCreateFolder = async ({ folderName }: { folderName: string }) => {
    setIsCreateFolderVisible(false);
    const match = location.pathname.match(/^\/files(\/.*)?$/);
    const currentLocation = match?.at(1) ?? '/';
    if (!isPending) {
      await mutateAsync({ folderName, location: currentLocation });
      await queryClient.invalidateQueries({ queryKey: ['files'] });
    }
  };

  const handleNewFolderClick = () => {
    setIsMenuVisible(false);
    setIsCreateFolderVisible(true);
  };

  const handleNewClick = () => {
    setIsMenuVisible(true);
  };

  return (
    <>
      <input
        type="file"
        id="file-input"
        onChange={onFileUpload}
        ref={fileInput}
        className="hidden"
        data-testid="file-input"
      />
      <OrganizationAcl>
        <Button
          ref={anchorEl}
          variant="contained"
          color="primary"
          fullWidth
          className="!my-md"
          onClick={handleNewClick}
          startIcon={<NewIcon className="text-white" />}
        >
          {t('layout.sidebar.new.button')}
        </Button>
      </OrganizationAcl>
      <Menu
        id="new-button-menu"
        anchorEl={anchorEl.current}
        open={isMenuVisible}
        onClose={() => setIsMenuVisible(false)}
      >
        <MenuItem className="!gap-x-2 !pl-2" onClick={handleUploadClick}>
          <PlatformOnly platform="app">
            <ListItemIcon className="!justify-center">
              <UploadFileIcon />
            </ListItemIcon>
            <ListItemText>{t('layout.sidebar.new.menu.upload')}</ListItemText>
          </PlatformOnly>
        </MenuItem>
        <MenuItem className="!gap-x-2 !pl-2" onClick={handleNewFolderClick}>
          <ListItemIcon className="!justify-center">
            <NewFolderIcon />
          </ListItemIcon>
          <ListItemText>{t('layout.sidebar.new.menu.newFolder')}</ListItemText>
        </MenuItem>
      </Menu>
      <NewFolderModal
        isOpen={isCreateFolderVisible}
        onClose={() => setIsCreateFolderVisible(false)}
        onSubmit={handleCreateFolder}
      />
      <Alert severity="error">
        {t('fileList.fileListContextMenu.newFolderModal.error')}
      </Alert>
    </>
  );
};

export default NewButton;
