import { Button, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Group } from '@app/services/groups/types';
import Loading from '@components/loading';
import useGroupFiles from '@pages/group-bulk-editing/hooks/useGroupFiles';
import GroupFilesTable from '@pages/group-files/components/group-files-table';

interface GroupDetailsFilesProps {
  group: Group;
}

const GroupDetailsFiles = ({ group }: GroupDetailsFilesProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: files, isLoading } = useGroupFiles({
    groupId: group.id,
    page: 1,
  });

  const filesTableWrapperClasses = useMemo(() => {
    if (files && files?.length > 0) {
      return 'cursor-pointer duration-200 hover:bg-slate-50';
    }

    return '';
  }, [files]);

  const navigateFiles = useCallback(() => {
    if (files && files?.length > 0) {
      navigate(`/groups/${group.id}/files`);
    }
  }, [group.id, files, navigate]);

  if (isLoading) {
    return (
      <div className="py-lg flex flex-1 justify-center">
        <Loading />
      </div>
    );
  }

  if (files) {
    return (
      <>
        <div className="gap-x-md mt-lg flex items-center">
          <Typography className="!text-2xl">
            {t('groups.groupDetails.files.title', {
              total: files.length,
            })}
          </Typography>
          <Button variant="outlined" onClick={navigateFiles}>
            {t('groups.groupDetails.files.manage')}
          </Button>
        </div>
        <div onClick={navigateFiles} className={filesTableWrapperClasses}>
          <GroupFilesTable fileIds={files.slice(0, 5)} group={group} readOnly />
        </div>
      </>
    );
  }
};

export default GroupDetailsFiles;
