import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useSyncFile = () => {
  const { syncFile } = useFilesApi();

  const mutation = useMutation({
    mutationFn: syncFile,
    mutationKey: ['syncFile'],
  });

  return mutation;
};

export default useSyncFile;
