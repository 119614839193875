import { useMutation } from '@tanstack/react-query';

import useTagsApi from '@app/services/tags/useTagsApi';

const useEditTag = () => {
  const { editTag } = useTagsApi();
  const query = useMutation({
    mutationFn: editTag,
    mutationKey: ['tag-edit'],
  });

  return query;
};

export default useEditTag;
