import { Typography } from '@mui/material';
import { PropsWithChildren, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Add from '@assets/files/add.svg?react';
import OrganizationAcl from '@components/organization-acl';
import PressableIcon from '@components/pressable-icon';

import { FileDetailsContext } from './file-details';
import Tags from './tags';

interface FileDetailsInfoProps {
  onDeleteTag: (tagId: string) => void;
  onAddTag: () => void;
  isDeleting: string | null;
}

const InfoTitle = ({ children }: PropsWithChildren) => (
  <Typography className="!mt-sm !text-sm !font-bold">{children}</Typography>
);
const InfoValue = ({ children }: PropsWithChildren) => (
  <Typography className="!max-w-full overflow-hidden overflow-ellipsis !text-sm">
    {children}
  </Typography>
);

const FileDetailsInfo = ({
  onDeleteTag,
  onAddTag,
  isDeleting,
}: FileDetailsInfoProps) => {
  const { t } = useTranslation();
  const details = useContext(FileDetailsContext)!;

  const handleDelete = (tagId: string) => {
    if (!isDeleting) {
      onDeleteTag(tagId);
    }
  };

  return (
    <div className="mb-md">
      <Typography>{t('fileList.fileDetails.info.title')}</Typography>
      <InfoTitle>{t('fileList.fileDetails.info.type')}</InfoTitle>
      <InfoValue>{details.type.toLocaleUpperCase()}</InfoValue>
      <span data-tour="tags-step-1">
        <InfoTitle>{t('fileList.fileDetails.info.tags')}</InfoTitle>
        <div className="gap-x-sm gap-y-sm mt-2 flex flex-1 flex-wrap items-center">
          <Tags
            tags={details.tags}
            onDelete={handleDelete}
            isDeleting={isDeleting}
          />
          <OrganizationAcl>
            <PressableIcon
              icon={<Add title="add-tag" height={12} width={12} />}
              onClick={onAddTag}
            />
          </OrganizationAcl>
        </div>
      </span>
      <InfoTitle>{t('fileList.fileDetails.info.size')}</InfoTitle>
      <InfoValue>{details.size}</InfoValue>
      <InfoTitle>{t('fileList.fileDetails.info.owner')}</InfoTitle>
      <InfoValue>{details.organizationName}</InfoValue>
      <InfoTitle>{t('fileList.fileDetails.info.location')}</InfoTitle>
      <Link
        className="!max-w-full overflow-hidden overflow-ellipsis !text-sm"
        to={details.path}
      >
        {details.path}
      </Link>
    </div>
  );
};

export default FileDetailsInfo;
