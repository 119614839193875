import { useQuery } from '@tanstack/react-query';

import { File } from '@app/services/files/types';
import useFilesApi, {
  fileResponseToFile,
} from '@app/services/files/useFilesApi';

const useFileDetails = (fileId: string) => {
  const { getFileDetails } = useFilesApi();

  const query = useQuery({
    queryFn: () => getFileDetails(fileId),
    queryKey: ['fileDetails', fileId],
    select: (data) => {
      const file: File | undefined = data
        ? fileResponseToFile(data.data)
        : undefined;

      return file;
    },
  });

  return query;
};

export default useFileDetails;
