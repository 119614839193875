import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAdvancedSearch from '@app/hooks/useAdvancedSearch';
import useAlert from '@app/hooks/useAlert';
import useAppJoyride from '@app/hooks/useAppJoyride';
import { useAppLocation } from '@app/hooks/useAppLocation';
import useErrorHandler from '@app/hooks/useErrorHandler';
import { useSearch, useSetSearch } from '@app/hooks/useSearch';
import { GroupFolder } from '@app/services/files/types';
import { AdvancedSearchFormInputs, File, Tag } from '@app/services/files/types';
import ErrorUploading from '@assets/layout/error-uploading.svg?react';
import Breadcrumb from '@components/breadcrumb';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import { useLayout } from '@pages/layout/layout';

import Droppable from './components/droppable';
import Empty from './components/empty';
import FileDetails from './components/file-details';
import FileList from './components/file-list';
import FilePreviewModal from './components/file-preview-modal';
import Filters from './components/filters';
import FolderDetails from './components/folder-details';
import SearchResults from './components/search-results';
import Sort from './components/sort';
import TagsEditorModal from './components/tags-editor-modal';
import useEditTags from './hooks/useEditTags';
import useFilter from './hooks/useFilter';
import useRemoveTag from './hooks/useRemoveTag';

const Files = () => {
  const filter = useFilter();
  const search = useSearch();
  const setSearch = useSetSearch();
  const { state } = useAppLocation<{ file?: File }>();
  const { parseToFilters, parse, toString } = useAdvancedSearch();
  const {
    filesQuery: { isLoading, files, groupFolders, isError, refetch },
    onFileMove,
    onNavigate,
    onDrop,
    sort: { direction, onDirectionChange, onSortOptionChange, sort, sortFiles },
  } = useLayout();
  const [fileDetail, setFileDetail] = useState<File | null>(null);
  const [previewOpen, setPreviewOpen] = useState<File | null>(null);
  const [isTagEditorOpen, setIsTagEditorOpen] = useState(false);
  const deleteTagMutation = useRemoveTag();
  const {
    mutateAsync: editTags,
    isError: isEditTagError,
    reset: resetEditTags,
  } = useEditTags();
  const { display: displayEditTagsAlert, Alert: AlertEditTags } = useAlert();
  useErrorHandler(isEditTagError, {
    callback: () => {
      displayEditTagsAlert();
      resetEditTags();
    },
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setStageConfig, advanceStep, pause, resume } = useAppJoyride();
  /* v8 ignore start */
  setStageConfig(6, [
    {
      onNext: () => {
        setIsTagEditorOpen(true);
        pause();
      },
    },
    {
      onNext: () => {
        setIsTagEditorOpen(false);
      },
    },
    {
      onNext: () => {
        pause();
        navigate('/tags');
      },
    },
  ]);
  /* v8 ignore stop */

  useEffect(() => {
    if (state?.file) {
      setFileDetail(state.file);
    } else {
      setFileDetail(null);
    }
  }, [state?.file]);

  const handleFolderNavigate = (file: File) => {
    if (search !== '') {
      setSearch(toString({ ...parse(search), path: file.path }));
    }

    navigate(`/files${file.path}`, { state: { file } });
  };

  const handleDoubleClick = (file: File) => {
    setFileDetail(null);
    setPreviewOpen(file);
  };

  const handleRemoveFilter = (name: keyof AdvancedSearchFormInputs) => {
    const advancedSearch = parse(search);
    delete advancedSearch[name];
    setSearch(toString(advancedSearch));
  };

  const handleAddTag = () => {
    setTimeout(() => {
      advanceStep(6, 0);
      setIsTagEditorOpen(true);
    }, 200);
  };

  useEffect(() => {
    if (isTagEditorOpen) {
      setTimeout(() => {
        /* v8 ignore next */
        resume();
      }, 400);
    }
  }, [isTagEditorOpen, resume]);

  const handleGroupFolderClick = ({ id }: GroupFolder) => {
    navigate(`/groups/${id}`);
  };

  const handleEditTags = async ({
    tags,
    fileId,
  }: {
    fileId: string;
    tags: Tag[];
  }) => {
    setIsTagEditorOpen(false);
    advanceStep(6, 1);
    await editTags({ tagNames: tags.map((tag) => tag.name), fileId });
  };

  const FileOrFolderDetails = ({ file }: { file: File }) =>
    file.type === 'folder' ? (
      <FolderDetails id={file.id} onClose={() => setFileDetail(null)} />
    ) : (
      <FileDetails
        id={file.id}
        onClose={() => setFileDetail(null)}
        deleteTagControls={deleteTagMutation}
        onAddTag={handleAddTag}
      />
    );

  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loading color="primary" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <ErrorUploading className="flex-3 mb-lg -mt-20" />
        <p className="mb-sm text-2xl font-medium">
          {t('fileList.error.title')}
        </p>
        <div className="mb-md flex">
          <Trans i18nKey="fileList.error.text">
            <p className="mr-1 text-lg">Please</p>
            <p
              className="cursor-pointer text-lg underline"
              onClick={() => refetch()}
            >
              try again
            </p>
            <p className="text-lg">.</p>
          </Trans>
        </div>
        <ErrorHelpReport />
      </div>
    );
  }

  if (files?.length === 0 && groupFolders?.length === 0) {
    return search === '' ? (
      <>
        <Empty onDrop={onDrop} />
        {fileDetail ? <FileOrFolderDetails file={fileDetail} /> : null}
      </>
    ) : (
      <div className="flex flex-1 flex-col">
        <Breadcrumb />
        <div className="flex flex-1 items-center" onDrop={onDrop}>
          <SearchResults results={sortFiles(filter(search, files))} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-row overflow-x-hidden" data-tour="files">
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex justify-between">
          <Breadcrumb />
          <Sort
            direction={direction}
            onDirectionChange={onDirectionChange}
            onSelectOption={onSortOptionChange}
            sortBy={sort}
          />
        </div>
        <Droppable
          className="flex flex-1"
          dropClassName="m-md flex flex-1 flex-col text-center"
          onDrop={onDrop}
        >
          <div className="gap-md p-md flex flex-1 flex-col">
            <Filters
              filters={parseToFilters(search)}
              onRemoveFilter={handleRemoveFilter}
            />
            {search === '' ? (
              <FileList
                onFileMove={onFileMove}
                onFolderHover={onNavigate}
                files={sortFiles(files)}
                onFileClick={setFileDetail}
                onFolderClick={handleFolderNavigate}
                onFileDoubleClick={handleDoubleClick}
                onGroupFolderClick={handleGroupFolderClick}
                groupFolders={groupFolders}
              />
            ) : (
              <SearchResults
                results={sortFiles(filter(search, files))}
                onFileClick={setFileDetail}
                onFolderClick={handleFolderNavigate}
                onFileDoubleClick={handleDoubleClick}
              />
            )}
          </div>
        </Droppable>
      </div>
      {fileDetail ? <FileOrFolderDetails file={fileDetail} /> : null}
      {fileDetail ? (
        <TagsEditorModal
          isOpen={isTagEditorOpen}
          onClose={() => setIsTagEditorOpen(false)}
          fileId={fileDetail.id}
          onSubmit={({ tags }) =>
            handleEditTags({ fileId: fileDetail.id, tags })
          }
        />
      ) : null}
      {previewOpen ? (
        <FilePreviewModal
          file={previewOpen}
          isOpen={!!previewOpen}
          onClose={() => setPreviewOpen(null)}
        />
      ) : null}
      <AlertEditTags severity="error">
        {t('fileList.fileDetails.tagsEditorModal.error')}
      </AlertEditTags>
    </div>
  );
};

export default Files;
