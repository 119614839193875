import { Box, Button, Modal, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Tag } from '@app/services/files/types';
import { MergeTagsRequest } from '@app/services/tags/types';
import { tagResponseToTag } from '@app/services/tags/useTagsApi';
import ErrorBubbleIcon from '@assets/organizations/error-bubble.svg?react';
import Close from '@assets/shared/close.svg?react';
import SuccessIcon from '@assets/shared/success.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import PressableIcon from '@components/pressable-icon';

import useMergeTags from '../hooks/useMergeTags';

import MergeTagsConfirmation from './merge-tags-confirmation';
import MergeTagsForm from './merge-tags-form';

interface MergeTagsModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialTag?: Tag;
}

const MergeTagsModal = ({
  isOpen,
  onClose,
  initialTag,
}: MergeTagsModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutateAsync: mergeTags,
    isError,
    isPending,
    reset,
    isSuccess,
    data,
  } = useMergeTags();

  const [mergeTagInfo, setMergeTagInfo] = useState<MergeTagsRequest | null>(
    null
  );

  const handleClose = () => {
    setMergeTagInfo(null);
    reset();
    onClose();
  };

  const handleTryAgain = () => {
    setMergeTagInfo(null);
    reset();
  };

  const handleMergeTags = async (mergeOptions: MergeTagsRequest) => {
    await mergeTags(mergeOptions);
    await queryClient.invalidateQueries({ queryKey: ['tags'] });
  };

  const Content = () => {
    if (isPending) {
      return (
        <div className="flex h-64 items-center justify-center">
          <Loading />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex h-64 flex-col items-center justify-center">
          <ErrorBubbleIcon className="mb-md mx-auto" />
          <p className="mx-lg mb-md text-center text-lg font-medium">
            <Trans i18nKey="tags.mergeTagsModal.error">
              Oops! There was an error merging tags!
              <span
                className="cursor-pointer underline"
                onClick={handleTryAgain}
              >
                Try Again?
              </span>
            </Trans>
          </p>
          <ErrorHelpReport />
        </div>
      );
    }

    if (mergeTagInfo) {
      if (isSuccess && data) {
        const handleNewTagNavigation = () => {
          navigate(`/tags/${data.data.id}`, {
            state: { tag: tagResponseToTag(data.data) },
          });
        };

        return (
          <div className="flex flex-col items-center">
            <SuccessIcon className="mt-md" />
            <p className="mt-sm text-lg font-medium">
              {t('tags.mergeTagsModal.success.title', {
                newTag: mergeTagInfo.newTagName,
              })}
            </p>
            <div className="gap-x-sm mt-md flex flex-1 justify-end self-end">
              <Button variant="outlined" onClick={handleClose}>
                {t('tags.mergeTagsModal.success.close')}
              </Button>
              <Button variant="contained" onClick={handleNewTagNavigation}>
                {t('tags.mergeTagsModal.success.access')}
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="mt-md flex flex-col">
          <MergeTagsConfirmation
            mergeTagInfo={mergeTagInfo}
            onCancel={handleClose}
            onSubmit={() => handleMergeTags(mergeTagInfo)}
          />
        </div>
      );
    }

    return (
      <div className="mt-md">
        <MergeTagsForm
          onCancel={handleClose}
          onSubmit={setMergeTagInfo}
          initialTag={initialTag}
        />
      </div>
    );
  };

  return (
    <Modal className="flex" open={isOpen} onClose={handleClose}>
      <Box className="w-medium-modal p-screen mx-auto my-auto !flex flex-col rounded bg-white shadow-md">
        <PressableIcon
          onClick={handleClose}
          icon={<Close title="close" />}
          className="!absolute self-end !p-0"
        />
        <Typography className="!text-2xl !font-medium">
          {t('tags.mergeTagsModal.title')}
        </Typography>
        <Content />
      </Box>
    </Modal>
  );
};

export default MergeTagsModal;
