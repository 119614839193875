import { TextField, TextFieldProps } from '@mui/material';
import { ClipboardEvent, forwardRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import { isValid } from '@app/utils/colors';

import ClickOutsideComponent from './click-outside';

type ColorInputProps = TextFieldProps & {
  value: string;
};

const ColorInput = forwardRef<HTMLInputElement, ColorInputProps>(
  (props, ref) => {
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);

    const handleChange = (newColor: string) => {
      if (isValid(newColor)) {
        // @ts-expect-error component does not need all event props
        props.onChange?.({ target: { value: newColor } });
      }
    };

    const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
      const paste = e.clipboardData.getData('text');
      handleChange(paste);
    };

    return (
      <div className="relative w-full">
        <TextField
          {...props}
          className="w-full"
          ref={ref}
          onClick={() => setIsColorPickerVisible(true)}
          InputProps={{
            readOnly: true,
            onPaste: handlePaste,
            endAdornment: (
              <div
                style={{ backgroundColor: props.value }}
                className="h-5 w-5 rounded-sm shadow-sm shadow-black"
              />
            ),
          }}
        />
        {isColorPickerVisible ? (
          <ClickOutsideComponent
            className="absolute right-0 top-14 z-10"
            onOutsideClick={() => setIsColorPickerVisible(false)}
          >
            <HexColorPicker
              data-testid="color-picker"
              color={props.value}
              onChange={handleChange}
            />
          </ClickOutsideComponent>
        ) : null}
      </div>
    );
  }
);

export default ColorInput;
