import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useChangeGroupMemberPermission = (memberId: string) => {
  const { changeMemberPermission } = useGroupsApi();

  const mutation = useMutation({
    mutationFn: changeMemberPermission,
    mutationKey: ['group-change-member-permission', memberId],
  });

  return mutation;
};

export default useChangeGroupMemberPermission;
