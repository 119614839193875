import { useQuery } from '@tanstack/react-query';

import useOrganizationApi from '@app/services/organizations/useOrganizationApi';

const useOrganizationById = (id?: string) => {
  const { getOrganizationById } = useOrganizationApi();

  const query = useQuery({
    queryFn: () => getOrganizationById(id!),
    queryKey: ['organization-by-id', id],
    select: ({ data }) => data,
    enabled: !!id,
  });

  return query;
};

export default useOrganizationById;
