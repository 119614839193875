import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';

import useDebounce from '@app/hooks/useDebounce';
import { FileAccessLevel } from '@app/services/files/types';
import { File, Group } from '@app/services/groups/types';
import useGroupPermissions from '@pages/groups/hooks/useGroupPermissions';

import useUpdateFilePermissions from './useUpdateFilePermissions';

interface UseManageUpdateFilePermissionsOptions {
  watch: UseFormWatch<File['permissions']>;
  file: File;
  group: Group;
}

const useManageUpdateFilePermissions = ({
  watch,
  file,
  group,
}: UseManageUpdateFilePermissionsOptions) => {
  const [safeValues, setSafeValues] = useState(file.permissions);
  const watchRead = watch('read');
  const watchWrite = watch('write');
  const watchCreateNotes = watch('create_notes');
  const watchDelete = watch('delete');
  const watchReadNotes = watch('read_notes');
  const watchUpdatePermissions = watch('update_permissions');
  const { canEditFiles } = useGroupPermissions(group.permission);

  const {
    mutateAsync: updateFilePermissions,
    isError: isUpdateFilePermissionError,
    isSuccess: isUpdateFilePermissionSuccess,
    reset: resetUpdateFilePermission,
  } = useUpdateFilePermissions();

  const debounce = useDebounce({
    value: JSON.stringify([
      watchRead,
      watchWrite,
      watchReadNotes,
      watchCreateNotes,
      watchDelete,
      watchUpdatePermissions,
    ]),
    delay: 1000,
  });
  const [
    read,
    write,
    readNotes,
    createNotes,
    deletePermission,
    updatePermissions,
  ] = JSON.parse(debounce) as [
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
  ];

  useEffect(() => {
    if (isUpdateFilePermissionSuccess) {
      setSafeValues({
        read,
        write,
        create_notes: createNotes,
        delete: deletePermission,
        read_notes: readNotes,
        update_permissions: updatePermissions,
      });
      resetUpdateFilePermission();
    }
  }, [
    createNotes,
    deletePermission,
    isUpdateFilePermissionSuccess,
    read,
    readNotes,
    resetUpdateFilePermission,
    updatePermissions,
    write,
  ]);

  useEffect(() => {
    if (!canEditFiles()) {
      return;
    }
    if (
      read !== file.permissions.read ||
      write !== file.permissions.write ||
      readNotes !== file.permissions.read_notes ||
      createNotes !== file.permissions.create_notes ||
      deletePermission !== file.permissions.delete ||
      updatePermissions !== file.permissions.update_permissions
    ) {
      const newPermissions: File['permissions'] = {
        create_notes: createNotes,
        delete: deletePermission,
        read,
        read_notes: readNotes,
        update_permissions: updatePermissions,
        write,
      };
      void updateFilePermissions({
        groupId: group.id,
        fileId: file.id,
        newPermissions: (
          Object.keys(newPermissions) as FileAccessLevel[]
        ).filter((key) => newPermissions[key]),
      });
    }
  }, [
    file.id,
    file.permissions.read,
    file.permissions.write,
    file.permissions.read_notes,
    file.permissions.create_notes,
    file.permissions.delete,
    file.permissions.update_permissions,
    updateFilePermissions,
    read,
    write,
    readNotes,
    createNotes,
    deletePermission,
    updatePermissions,
    canEditFiles,
    group.id,
  ]);

  return {
    safeValues,
    isUpdateFilePermissionError,
    resetUpdateFilePermission,
  };
};

export default useManageUpdateFilePermissions;
