import { Button } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import NotVerified from '@assets/organizations/not-verified.svg?react';
import Verified from '@assets/organizations/verified.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';

import useVerifyDns from '../hooks/useVerifyDns';

interface DnsVerificationProps {
  domain: string;
  onVerified: () => void;
}

const DnsVerification = ({ domain, onVerified }: DnsVerificationProps) => {
  const { t } = useTranslation();
  const { refetch: fetch, isError, data, isFetching } = useVerifyDns(domain);

  const handleVerification = async () => {
    const { data: verification } = await fetch();
    if (verification?.verified) {
      onVerified();
    }
  };

  if (isFetching) {
    return (
      <div className="gap-y-sm gap-x-sm flex flex-1">
        <Loading size={24} />
        <p className="text-sm">
          {t('organization.newOrganization.dns.verify.creating')}
        </p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="gap-y-sm flex flex-1 flex-col items-center">
        <p className="text-sm">
          <Trans i18nKey="organization.newOrganization.dns.verify.error">
            Error verifying DNS record! Please
            <span
              className="cursor-pointer underline"
              onClick={handleVerification}
            >
              try again
            </span>
          </Trans>
        </p>
        <ErrorHelpReport />
      </div>
    );
  }

  if (data?.verified === false) {
    return (
      <div className="gap-y-sm flex flex-col">
        <div className="gap-x-sm flex">
          <NotVerified className="text-red-400/60" />
          <p>{t('organization.newOrganization.dns.verify.notVerified')}</p>
        </div>
        <div className="flex">
          <Button onClick={handleVerification}>
            {t('organization.newOrganization.dns.verify.verify')}
          </Button>
        </div>
      </div>
    );
  }

  if (data?.verified) {
    return (
      <div className="gap-y-sm gap-x-sm flex flex-1">
        <Verified className="text-green-400/60" />
        <p>{t('organization.newOrganization.dns.verify.verified')}</p>
      </div>
    );
  }

  return (
    <div className="gap-y-sm gap-x-sm flex flex-1">
      <Button onClick={handleVerification}>
        {t('organization.newOrganization.dns.verify.verify')}
      </Button>
    </div>
  );
};

export default DnsVerification;
