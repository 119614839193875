import { useQuery } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

interface UseGroupFilesOptions {
  groupId: string;
  page: number;
}

const useGroupFiles = ({ groupId, page }: UseGroupFilesOptions) => {
  const { getFiles } = useGroupsApi();
  const query = useQuery({
    queryFn: () => getFiles({ page, groupId }),
    queryKey: ['files', page, groupId],
    staleTime: 5 * 60 * 1000,
    select: (data) => data.data,
  });

  return query;
};

export default useGroupFiles;
