import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useCreateGroup = () => {
  const { createGroup } = useGroupsApi();
  const query = useMutation({
    mutationFn: createGroup,
    mutationKey: ['groups-create-group'],
  });

  return query;
};

export default useCreateGroup;
