import { useMutation } from '@tanstack/react-query';

import useOrganizationApi from '@app/services/organizations/useOrganizationApi';

const useRemoveUserFromOrganization = () => {
  const { removeUserFromOrganization } = useOrganizationApi();
  const mutation = useMutation({
    mutationFn: removeUserFromOrganization,
    mutationKey: ['remove-user-from-organization'],
  });

  return mutation;
};

export default useRemoveUserFromOrganization;
