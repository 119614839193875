import { useAuth0 } from '@auth0/auth0-react';

import { getEnv } from '@app/config/env';

import getNotesMock from './mock';
import { getNotesService } from './service';
import { NotesApi } from './types';

const useNotesApi = (): NotesApi => {
  const { getAccessTokenSilently } = useAuth0();

  if (getEnv().mockApiCalls) {
    return getNotesMock();
  }

  return getNotesService({ getAccessTokenSilently });
};

export default useNotesApi;
