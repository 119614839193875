import { useAuth0 } from '@auth0/auth0-react';

import { getEnv } from '@app/config/env';

import getUsersMock from './mock';
import getUsersService from './service';
import { UsersApi } from './types';

const useUsersApi = (): UsersApi => {
  const { getAccessTokenSilently } = useAuth0();

  if (getEnv().mockApiCalls) {
    return getUsersMock();
  }

  return getUsersService({ getAccessTokenSilently });
};

export default useUsersApi;
