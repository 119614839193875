import { useMutation } from '@tanstack/react-query';

import useGroupsApi from '@app/services/groups/useGroupsApi';

const useDeleteGroup = () => {
  const { deleteGroup } = useGroupsApi();

  const mutation = useMutation({
    mutationFn: deleteGroup,
    mutationKey: ['delete-group'],
  });

  return mutation;
};

export default useDeleteGroup;
