import { Button, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ShareFile } from '@app/services/files/types';
import UsersAvatar from '@components/users-avatar';

import useShareFiles from '../hooks/useShareFile';

import { FileDetailsContext } from './file-details';
import ManageAccessModal from './manage-access-modal';
import ShareFileConfirmationModal from './share-file-confirmation-modal';
import ShareFileModal from './share-file-modal';

interface FileDetailsPeopleProps {
  usersId: string[];
  ownerId: string;
}

const FileDetailsPeople = ({ usersId, ownerId }: FileDetailsPeopleProps) => {
  const { t } = useTranslation();
  const file = useContext(FileDetailsContext);
  const { mutateAsync: shareFiles, reset } = useShareFiles();
  const [isUserInviteOpen, setIsUserInviteOpen] = useState(false);
  const [isManageAccessModalVisible, setIsManageAccessModalVisible] =
    useState(false);
  const [isShareConfirmationOpen, setIsShareConfirmationOpen] = useState(false);
  const [shareParams, setShareParams] = useState<ShareFile | null>(null);

  const handleContinue = (share: ShareFile) => {
    setShareParams(share);
    setIsShareConfirmationOpen(true);
    setIsUserInviteOpen(false);
  };

  const handleShareFile = () => {
    if (shareParams) {
      const users = shareParams.users.map((user) =>
        typeof user === 'string' ? user : user.id
      );
      if (file) {
        void shareFiles({
          ...shareParams,
          users,
          startDate: shareParams.startDate.toISODate(),
          expirationDate: shareParams.expirationDate?.toISODate() ?? null,
          fileId: file.id,
        });
      }
      setTimeout(() => {
        setIsManageAccessModalVisible(true);
      }, 200);
      setTimeout(() => {
        setIsShareConfirmationOpen(false);
      }, 200);
    }
  };

  const handleOpenShareFileModal = () => {
    reset();
    setTimeout(() => {
      setIsUserInviteOpen(true);
    }, 200);
    setTimeout(() => {
      setIsManageAccessModalVisible(false);
      setIsShareConfirmationOpen(false);
    }, 200);
  };

  return (
    <>
      <div>
        <Typography className="!mb-sm">
          {t('fileList.fileDetails.users.peopleWithAccess')}
        </Typography>
        <div className="flex">
          <UsersAvatar
            usersId={usersId}
            ownerId={ownerId}
            onAdd={() => setIsUserInviteOpen(true)}
          />
        </div>
        <Button
          variant="outlined"
          color="primary"
          className="!mt-sm !px-md"
          onClick={() => setIsManageAccessModalVisible(true)}
        >
          {t('fileList.fileDetails.users.manageAccess')}
        </Button>
      </div>

      <ShareFileModal
        isOpen={isUserInviteOpen}
        onClose={() => setIsUserInviteOpen(false)}
        onContinue={handleContinue}
        initialUsers={shareParams?.users}
      />
      {shareParams ? (
        <ShareFileConfirmationModal
          isOpen={isShareConfirmationOpen}
          onClose={() => setIsShareConfirmationOpen(false)}
          onSubmit={handleShareFile}
          shareParams={shareParams}
          onGoBack={handleOpenShareFileModal}
        />
      ) : null}
      <ManageAccessModal
        isOpen={isManageAccessModalVisible}
        onClose={() => setIsManageAccessModalVisible(false)}
        file={file}
      />
    </>
  );
};

export default FileDetailsPeople;
