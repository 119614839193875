import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import useUsersApi from '@app/services/users/useUsersApi';

const PostLogin = () => {
  const { user } = useAuth0();
  const { postLogin } = useUsersApi();
  const { mutateAsync } = useMutation({
    mutationFn: postLogin,
    mutationKey: ['post-login'],
  });

  useEffect(() => {
    if (user?.sub) {
      mutateAsync(user.sub).catch(() => {});
    }
  }, [mutateAsync, user]);

  // useEffect(() => {
  //   if (isRunningOnElectron()) {
  //     window.auth.onLogin((url) => {
  //       void handleRedirectCallback(url);
  //     });
  //   }
  // }, [handleRedirectCallback]);

  return <></>;
};

export default PostLogin;
