import { alpha, Chip, Tooltip, useTheme } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useErrorHandler from '@app/hooks/useErrorHandler';
import useUserHasAccount from '@app/hooks/useUserHasAccount';
import WarningIcon from '@assets/shared/warning.svg?react';
import { useUserInputContext } from '@pages/group-members/hooks/useUserInputContext';

import Loading from './loading';

interface UserInputEmailChipProps {
  label: string;
  onDelete: (label: string) => void;
  onUserFromOutside: () => void;
}

const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const UserInputEmailChip = ({
  label,
  onDelete,
  onUserFromOutside,
}: UserInputEmailChipProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const { setIsAvailable } = useUserInputContext();

  const { data, isLoading, isError, isFetched } = useUserHasAccount(
    label,
    emailRegex.test(label.trim())
  );
  useErrorHandler(isError, {
    callback: () => {
      onDelete(label);
    },
  });

  useEffect(() => {
    if (isFetched) {
      setIsAvailable(true);
    }
  }, [isFetched, setIsAvailable]);

  const userStatus = useMemo(() => {
    if (isLoading) {
      return {
        background: undefined,
        message: undefined,
        icon: <Loading size={12} className="!ml-2" />,
      };
    }

    // TODO: Validate user's organization
    if (label.split('@')[1] && label.split('@')[1] !== 'filot.io') {
      onUserFromOutside();

      return {
        background: alpha(palette.danger.main, 0.5),
        message: t('components.usersInputEmailChip.userFromOutside'),
        icon: (
          <WarningIcon
            title="warningIcon"
            width={12}
            height={12}
            className="!ml-2"
            color={alpha(palette.warning.contrastText, 0.5)}
          />
        ),
      };
    }
    if (data === false) {
      onUserFromOutside();

      return {
        background: alpha(palette.warning.main, 0.5),
        message: t('components.usersInputEmailChip.userWithoutAccount'),
        icon: (
          <WarningIcon
            title="warningIcon"
            width={12}
            height={12}
            className="!ml-2"
            color={alpha(palette.warning.contrastText, 0.5)}
          />
        ),
      };
    }

    return { background: undefined, message: undefined, icon: undefined };
  }, [data, isLoading, label, onUserFromOutside, palette, t]);

  return (
    <Tooltip title={userStatus.message} arrow>
      <Chip
        key={label}
        label={label}
        onDelete={() => onDelete(label)}
        size="small"
        icon={userStatus.icon}
        className="!mr-sm !my-0.5"
        sx={{
          bgcolor: userStatus.background,
        }}
      />
    </Tooltip>
  );
};

export default UserInputEmailChip;
