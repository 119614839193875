import { Button } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { organizationMock } from '@app/test/mocks/organization-mocks';

import useInvitation from '../hooks/useInvitation';
import useOrganizationById from '../hooks/useOrganizationById';

import AcceptInviteModal from './accept-invite-modal';

const OrganizationInviteAlert = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useInvitation();
  const { data: organization, refetch } = useOrganizationById(
    data?.auth0_invitation_object.organization_id
  );

  useEffect(() => {
    if (data?.auth0_invitation_object.organization_id) {
      void refetch();
    }
  }, [data?.auth0_invitation_object.organization_id, refetch]);

  if (!data) {
    return null;
  }

  return (
    <>
      <div className="mt-screen px-screen absolute w-full">
        <div className="bg-primary/5 p-md flex items-center justify-between rounded-md">
          <span>
            <p className="text-lg">
              {t('organization.invitation.text', {
                organizationName:
                  organization?.name ??
                  data.auth0_invitation_object.inviter.name,
              })}
            </p>
            <p className="text-lg">
              {t('organization.invitation.accept', {
                relative: DateTime.fromISO(
                  data.auth0_invitation_object.expires_at
                ).toRelativeCalendar(),
              })}
            </p>
          </span>
          <Button variant="contained" onClick={() => setIsOpen(true)}>
            {t('organization.invitation.button')}
          </Button>
        </div>
      </div>
      <AcceptInviteModal
        organization={organizationMock}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default OrganizationInviteAlert;
