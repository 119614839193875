import { Paginated, SuccessfulResponse } from '@filot/types/api';

import mock from '../../../../../mock/tags.json';

import { TagResponse, TagsApi } from './types';
import { tagToTagResponse } from './useTagsApi';

const getTagsMock = (): TagsApi => {
  const tags: TagResponse[] = mock;

  return {
    get: (_paginationOptions, filters) =>
      new Promise<Paginated<TagResponse>>((res, rej) => {
        setTimeout(() => {
          if (Math.random() < 0.5) {
            rej(new Error('MOCK_ERROR'));
          } else {
            if (!filters) {
              res({
                success: true,
                message: 'paginated',
                pagination: {
                  total: tags.length,
                  page_size: 10,
                  pages: 1,
                  page: 1,
                  links: {
                    self: '',
                  },
                },
                data: tags,
              });
            } else {
              const filtered = tags.filter((tag) =>
                tag.name
                  .toLocaleLowerCase()
                  .includes(filters.toLocaleLowerCase())
              );
              res({
                success: true,
                message: 'paginated',
                pagination: {
                  total: filtered.length,
                  page_size: 10,
                  pages: 1,
                  page: 1,
                  links: {
                    self: '',
                  },
                },
                data: filtered,
              });
            }
          }
        }, 1000);
      }),

    changeColors: ({ background, text, id }) =>
      new Promise<void>((res, rej) => {
        const tagToEdit = tags.find((tag) => tag.id === id);
        setTimeout(() => {
          if (Math.random() < 0.5 || !tagToEdit) {
            rej(new Error('MOCK_ERROR'));
          } else {
            if (!tagToEdit.styling) {
              tagToEdit.styling = {
                background_color: background,
                text_color: text,
              };
            } else {
              tagToEdit.styling.background_color = background;
              tagToEdit.styling.text_color = text;
            }
            res();
          }
        }, 1000);
      }),

    editTag: ({ description, name, id }) =>
      new Promise<void>((res, rej) => {
        const tagToEdit = tags.find((tag) => tag.id === id);
        setTimeout(() => {
          if (Math.random() < 0.5 || !tagToEdit) {
            rej(new Error('MOCK_ERROR'));
          } else {
            if (name) {
              tagToEdit.name = name;
            }
            if (description) {
              tagToEdit.description = description;
            }
            res();
          }
        }, 1000);
      }),

    mergeTags: ({ firstTag }) =>
      new Promise<SuccessfulResponse<TagResponse>>((res, rej) => {
        setTimeout(() => {
          if (Math.random() < 0.5) {
            rej(new Error('MOCK_ERROR'));
          } else {
            res({
              data: tagToTagResponse(firstTag),
              message: 'success',
              success: true,
            });
          }
        }, 1000);
      }),

    createTag: () =>
      new Promise<void>((res, rej) => {
        setTimeout(() => {
          if (Math.random() < 0.5) {
            rej(new Error('MOCK_ERROR'));
          } else {
            res();
          }
        }, 1000);
      }),

    deleteTag: (tagId) =>
      new Promise<void>((res, rej) => {
        const tag = tags.find(({ id }) => id === tagId);
        setTimeout(() => {
          if (Math.random() < 0.5 || !tag) {
            rej(new Error('MOCK_ERROR'));
          } else {
            res();
          }
        }, 1000);
      }),

    upsertTags: () =>
      new Promise<void>((res, rej) => {
        setTimeout(() => {
          if (Math.random() < 0.5) {
            rej(new Error('MOCK_ERROR'));
          } else {
            res();
          }
        }, 1000);
      }),

    removeTag: () =>
      new Promise<void>((res, rej) => {
        setTimeout(() => {
          if (Math.random() < 0.5) {
            rej(new Error('MOCK_ERROR'));
          } else {
            res();
          }
        }, 1000);
      }),
  };
};

export default getTagsMock;
