import { useMutation } from '@tanstack/react-query';

import useFilesApi from '@app/services/files/useFilesApi';

const useCreateVersion = () => {
  const { createVersion } = useFilesApi();

  const mutation = useMutation({
    mutationFn: createVersion,
    mutationKey: ['files-version-create'],
  });

  return mutation;
};

export default useCreateVersion;
