import { useState } from 'react';
import { Trans } from 'react-i18next';

import { File } from '@app/services/files/types';
import ErrorIcon from '@assets/shared/error.svg?react';
import ErrorHelpReport from '@components/error-help-report';
import Loading from '@components/loading';
import FileList from '@pages/files/components/file-list';
import useFiles from '@pages/layout/hooks/useFiles';

import {
  useSelectedFiles,
  useUpdateSelectedFiles,
} from '../hooks/useSelectedFiles';

import Empty from './empty';
import SelectFilesPath from './select-files-path';

const GroupAddFilesSelectFiles = () => {
  const selectedFiles = useSelectedFiles();
  const dispatchSelectedFiles = useUpdateSelectedFiles();
  const [path, setPath] = useState('');
  const { data, isLoading, refetch, isError } = useFiles({
    path,
  });

  const handleFileClick = (file: File) => {
    if (selectedFiles.some(({ id }) => id === file.id)) {
      dispatchSelectedFiles({
        type: 'REMOVE_FILE',
        payload: file.id,
      });
    } else {
      dispatchSelectedFiles({
        type: 'ADD_FILE',
        payload: {
          id: file.id,
          name: file.fileName,
          type: file.type,
        },
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <ErrorIcon />
        <p className="mb-lg mt-md text-lg font-medium">
          <Trans i18nKey="groups.addFiles.errorLoading">
            Oops! We could not load files.
            <span
              className="cursor-pointer underline"
              onClick={() => refetch()}
            >
              Try again?
            </span>
          </Trans>
        </p>
        <ErrorHelpReport />
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-col">
      <SelectFilesPath path={path} onPathChange={setPath} />
      <div className="p-screen flex flex-1">
        {data?.files && data.files.length > 0 ? (
          <FileList
            onFileClick={handleFileClick}
            files={data?.files}
            onFolderClick={(folder) => setPath(folder.path)}
            selectedFilesId={selectedFiles.map(({ id }) => id)}
          />
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};

export default GroupAddFilesSelectFiles;
