import { useMutation } from '@tanstack/react-query';

import useTagsApi from '@app/services/tags/useTagsApi';

const useEditTags = () => {
  const { upsertTags } = useTagsApi();
  const query = useMutation({
    mutationFn: upsertTags,
    mutationKey: ['tags-upsert'],
  });

  return query;
};

export default useEditTags;
