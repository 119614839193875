import { useQuery } from '@tanstack/react-query';

import useOrganizationApi from '@app/services/organizations/useOrganizationApi';

const useOrganization = (userId?: string) => {
  const { getOrganization } = useOrganizationApi();

  const query = useQuery({
    queryFn: () => getOrganization(userId),
    queryKey: ['organizations', userId],
    refetchOnMount: true,
    select: (data) => (data === null ? null : data.data[0]),
  });

  return query;
};

export default useOrganization;
