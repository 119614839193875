import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { File } from '@app/services/groups/types';

interface BulkEditPermissionsFormProps {
  onSubmit: (permissions: File['permissions']) => void;
}

const BulkEditPermissionsForm = ({
  onSubmit,
}: BulkEditPermissionsFormProps) => {
  const { t } = useTranslation();

  const { handleSubmit, control, setValue, watch } = useForm<
    File['permissions'] & { noPermissions: boolean }
  >({
    defaultValues: {
      read: false,
      write: false,
      create_notes: false,
      delete: false,
      read_notes: false,
      update_permissions: false,
      noPermissions: true,
    },
  });

  const read = watch('read');
  const write = watch('write');
  const createNotes = watch('create_notes');
  const readNotes = watch('read_notes');
  const deletePermission = watch('delete');
  const updatePermissions = watch('update_permissions');

  useEffect(() => {
    if (
      read ||
      write ||
      createNotes ||
      readNotes ||
      deletePermission ||
      updatePermissions
    ) {
      setValue('noPermissions', false);
    } else {
      setValue('noPermissions', true);
    }
  }, [
    read,
    write,
    setValue,
    createNotes,
    readNotes,
    deletePermission,
    updatePermissions,
  ]);

  const handleRemoveClick = (
    e: ChangeEvent<HTMLInputElement>,
    callback: (e: ChangeEvent<HTMLInputElement>) => void
  ) => {
    if (
      !read &&
      !write &&
      !createNotes &&
      !readNotes &&
      !deletePermission &&
      !updatePermissions
    ) {
      return;
    }
    if (e.target.value) {
      setValue('create_notes', false);
      setValue('read_notes', false);
      setValue('delete', false);
      setValue('update_permissions', false);
      setValue('read', false);
      setValue('write', false);
      callback(e);
    }
  };

  const handleWriteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('write', e.target.checked);
    setValue('read', read || e.target.checked);
  };

  const handleCreateNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('create_notes', e.target.checked);
    setValue('read_notes', readNotes || e.target.checked);
  };

  return (
    <FormGroup className="mt-lg px-sm">
      <ul>
        <li>
          <FormControl>
            <Controller
              name="read"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={write}
                      {...field}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.read')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>

        <li>
          <FormControl>
            <Controller
              name="write"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => handleWriteChange(e)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.write')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>

        <li>
          <FormControl>
            <Controller
              name="read_notes"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={createNotes}
                      {...field}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.readNotes')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>

        <li>
          <FormControl>
            <Controller
              name="create_notes"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => handleCreateNoteChange(e)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.createNotes')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>

        <li>
          <FormControl>
            <Controller
              name="delete"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.delete')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>

        <li>
          <FormControl>
            <Controller
              name="update_permissions"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.updatePermissions')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>

        <li>
          <FormControl>
            <Controller
              name="noPermissions"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      onChange={(e) => handleRemoveClick(e, field.onChange)}
                      checked={field.value}
                    />
                  }
                  label={
                    <Typography className="!text-lg">
                      {t('groups.bulkEditing.setPermissions.remove')}
                    </Typography>
                  }
                />
              )}
            />
          </FormControl>
        </li>
      </ul>
      <div className="flex flex-1 justify-end">
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          className="!my-sm !px-lg"
        >
          {t('groups.bulkEditing.setPermissions.review')}
        </Button>
      </div>
    </FormGroup>
  );
};

export default BulkEditPermissionsForm;
