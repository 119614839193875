import { useTranslation } from 'react-i18next';

import { UsersWithAccess } from '@app/services/files/types';

import ManageAccessPeoplePerson from './manage-access-people-person';

interface ManageAccessPeopleProps {
  emails: string[];
  users: UsersWithAccess;
}

const ManageAccessPeople = ({ emails, users }: ManageAccessPeopleProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt-md">
      <p className="px-screen text-xl">
        {t('fileList.fileDetails.manageAccessModal.form.people.title')}
      </p>
      <ul className="gap-y-sm my-sm flex max-h-72 flex-col overflow-y-auto">
        {Object.keys(users).map((userId) => (
          <ManageAccessPeoplePerson
            userId={userId}
            key={userId}
            accessLevel={users[userId]}
          />
        ))}
        {emails.map((email) => (
          <ManageAccessPeoplePerson
            email={email}
            key={email}
            accessLevel={null}
          />
        ))}
      </ul>
    </div>
  );
};

export default ManageAccessPeople;
